import React from 'react';
import {submitLeadData,leadSendOtpLandingPage} from '../../Services/user-service';
import CircularLoader from '../../Components/circular-loader/circular-loader';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';


export default class OTP extends React.Component{
    constructor(){
        super();
        this.state={
            submitLoader: false,
            page:"submitOtp",
            name:"",
            mob:"",
            mobError:"",
            formControls:{
                otp:{
                    value: null,
                    error:null
                }
            }
        }
    }

    componentDidMount(){
        document.getElementsByTagName('body')[0].style.backgroundColor = "#E3F4FF";  
        document.getElementById("indexWhatsApp") && document.getElementById("indexWhatsApp").remove();
        let s= decodeURI(window.location.search); 
        s && s.split("&").map((item)=>{
                    if(item.split("=")[0]==="name"){
                        this.setState({
                            name:item.split("=")[1]
                        })
                    }
                else if(item.split("=")[0]==="phone_number"){
                    const value =parseInt(item.split("=")[1]);
                    this.setState({
                       mob: value
                    });
                }    
            })  
    }

    dataSentToBackend(){
        let arr = window.location.search;
        let str=arr.concat("&otp").concat("=").concat(this.state.formControls.otp.value);
        return str;
    }

    resendOTP(e){
        e.preventDefault();
        if(parseInt(this.state.mob.length) !== parseInt(10)){
            this.setState({
                mobError:"Please enter a valid 10 digit mobile number"
            })
            return;
        }else{
            this.setState({
                mobError: ""
            })
        }
        
        let s= decodeURI(window.location.search); 
        let URLarr = s.split("&");
        for(let i=0;i<URLarr.length;i++){
            if(URLarr[i].split("=")[0]==="phone_number" || URLarr[i].split("=")[0]==="mobile"){
                let arr = URLarr[i].split("=");
                arr[1] = this.state.mob;
                URLarr[i]=arr.join("=");  
            }
        } 
        
        let body = URLarr.join("&");
        console.log(body);
        leadSendOtpLandingPage(body)
        .then(response => response.json())
        .then(data => {
            if(data.success){
                this.setState({
                    submitLoader: false,   
                    page:"submitOtp",
                    formControls: {
                        ...this.state.formControls,
                        otp:null
                    }                
                })
            }else{
                this.setState({
                    submitLoader: false, 
                    mobError: data.message,  
                    page:"resendOtp"               
                })
                
            }
        }).catch(error => {
            console.error(error);
            ToastsStore.error("Something went wrong. Please try again later.!!!");
            this.setState({
                submitLoader: false,                   
            })
        })

    }
  
    changeHandlerMob(e){ 
        this.setState({
            mob: e.target.value
        })  
      
    }
  
    submitData(event){
        event.preventDefault();
        this.setState({
            submitLoader: true,
        });
        let body = this.dataSentToBackend();

        submitLeadData(body)
        .then(response => response.json())
        .then(data => {
            if(data.success){
                let temp2 = this.state.formControls;
                temp2.otp.error = null;
                this.setState({
                    submitLoader: false,
                    formControls: temp2,
                    page:"thankyou"                    
                })
            }else{
                let temp2 = this.state.formControls;
                temp2.otp.error = data.message;
                this.setState({
                    submitLoader: false,
                    formControls: temp2                    
                })
            }
        }).catch(error => {
            console.log(error);
            ToastsStore.error("Something went wrong. Please try again later.!!!");
            this.setState({
                submitLoader: false,                   
            })
        })
    }

    changeHandler = event => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
                formControls: {
                    ...this.state.formControls,
                    [name]: {
                    ...this.state.formControls[name],
                    value
                    }
                }
        });
    }

    render(){
        return(
            <React.Fragment>
                <div className="text--center" style={{backgroundColor:"white",padding:"1% 0"}}>
                     <img src="https://s3.amazonaws.com/assets.mockflow.com/app/wireframepro/company/C908d69f9ecd98b402668814a7a2882f9/projects/M8ac488e4f5743176787b8cedbf6235941599294444534/images/Me6b4ff9c034e48a5c1cdc9a3b8ae74381612764821526" alt="Logo" width="10%"/> 
                </div>
                <section>
                    <h3 className="text--center" style={{marginTop:"7%"}}>Hi {this.state.name}, Thank you for showing up your interest.</h3>
                    {
                        this.state.page === "submitOtp" &&
                    
                    <form id="data-form-otp" 
                        className="ui form" 
                        onSubmit={this.submitData.bind(this)}>
                        <p className="text--center padding-btm-One"><b>Please enter the OTP to verify.</b></p>
                        <div className="required field margin-btm">
                            <label>Submit OTP</label>
                            <div style={{fontSize:'12.3px'}}>OTP sent to your Phone Number <span style={{color:'#0c73a5'}}>&times;&times;&times;&times;&times;&times;{this.state.mob && this.state.mob.toString().slice(6,10)}</span></div>
                             <div className="ui input">
                                <input type="number" inputMode="numeric" pattern="[0-9]*" name="otp" value={this.state.formControls && this.state.formControls.otp && this.state.formControls.otp.value} onChange={this.changeHandler}/>
                            </div>
                            {
                                this.state.formControls && this.state.formControls.otp && this.state.formControls.otp.error &&
                                <div className="form-error text--center">{this.state.formControls && this.state.formControls.otp && this.state.formControls.otp.error}</div>
                            }
                        </div>
                        {
                            !this.state.submitLoader &&
                            <React.Fragment>
                                <input type="submit" className="medlife form-submit-btm" value="Submit OTP"/>
                                <div onClick={()=>this.setState({page :"resendOtp"})} className="margin-top--half" style={{color:'#0c73a5',cursor:'pointer'}}>
                                    <span>Change Phone Number</span>
                                </div>
                            </React.Fragment>
                        }
                       
                        {
                            this.state.submitLoader && 
                            <div className="margin-top--quar">
                                <CircularLoader stroke={"#0c73a5"} size={"36"} buttonSize={"50px"}></CircularLoader>
                            </div>
                        }
                    </form>
                    
                    }  
                   
                    {   this.state.page === "resendOtp" &&
                    <section id="data-form-otp" className="ui form">
                        <p className="text--center padding-btm-One"><b>Change phone number</b></p>
                        <div className="required field margin-btm">
                            <label>Phone Number</label>
                             <div className="ui input">
                                <input type="number" inputMode="numeric" pattern="[0-9]*"  value={this.state.mob} onChange={this.changeHandlerMob.bind(this)}/>
                            </div>
                            {
                                this.state.mobError &&
                                <div className="form-error text--center">{this.state.mobError}</div>
                            }
                        </div>
                     
                        <article className="margin-top col-20">
                            <button 
                            onClick={this.resendOTP.bind(this)} 
                            className="medlife form-submit-btm">Resend OTP</button>           
                        </article>
                     
                      
                        {
                            this.state.submitLoader && 
                            <div className="margin-top--quar">
                                <CircularLoader stroke={"#0c73a5"} size={"36"} buttonSize={"50px"}></CircularLoader>
                            </div>
                        }
                    </section>
                    } 

                    {
                        this.state.page === "thankyou" &&
                        <section id="data-form-otp" >
                            <div style={{fontWeight:'700',fontSize:'20px',color:'rgb(12, 115, 165)'}} class="text--center">You have taken first step towards Financial Security.</div>
                            <div style={{fontWeight:'700',fontSize:'14px',fontStyle:'normal',marginTop:'8px',textAlign:"center"}}>Our representative will reach out to you soon.</div>
                        </section>
                    }
                      
                </section> 
                <ToastsContainer position={ToastsContainerPosition.TOP_RIGHT} lightBackground store={ToastsStore} /> 
            </React.Fragment>
        )
    }
}