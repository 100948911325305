import React from 'react';
import './Modal.css';

export default function Modal(props){
    return(
        <React.Fragment>
            
            <section className={props.active ? 'modal active' : 'modal' }  id="modal">
                <div className="modal-header">
                    <div className="close-button" onClick={props.back}>&#x2190;</div>
                    <div className="close-button" onClick={props.close}>&times;</div>
                </div>
                <div className="modal-body">
                    {props.children}
                </div>
            </section>
            <div className="overlay"></div>
        </React.Fragment>
    )
}
