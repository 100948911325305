import React from 'react'; 
import Logo from './Logo';
import Banner from './Banner';
import OrangeLabel from './OrangeLabel';
import CoverPage from './CoverPage';
import Bottom from './Bottom';
import CopyRight from './CopyRight';
import './OneVaidyaLifeInsurance.css';
// import Form from '../../LandingPage/LandingPage';
// import utils from '../../../Services/utility-service';

export default class OneVaidyaLifeInsurance extends React.Component{
    constructor(props){
        super(props);
        this.state={
            bannerImg:[{
                id:0,
                path:"https://s3-reachlocalads.s3.ap-south-1.amazonaws.com/imgs/landing-page/onevaidya/cbf64ab0-1518739.svg",
                caption:"Life Insurance Cover"
            },
            {    id:1,
                path:"https://s3-reachlocalads.s3.ap-south-1.amazonaws.com/imgs/landing-page/onevaidya/ff7c05fb-845808.svg",
                caption:"Loyalty Sum Assured"
            },
            {    id:2,
                path:"https://s3-reachlocalads.s3.ap-south-1.amazonaws.com/imgs/landing-page/onevaidya/e4383bff-2171016.svg",
                caption:"Tax Benefits"
            },
            {    id:3,
                path:"https://s3-reachlocalads.s3.ap-south-1.amazonaws.com/imgs/landing-page/onevaidya/ac671450-858699.svg",
                caption:"Terminal Bonus"
            },
            {    id:4,
                path:"https://s3-reachlocalads.s3.ap-south-1.amazonaws.com/imgs/landing-page/onevaidya/960f0632-2448368.svg",
                caption:"Maturity Benefit"
            },
            {    id:4,
                path:"https://s3-reachlocalads.s3.ap-south-1.amazonaws.com/imgs/landing-page/onevaidya/fde5d1a4-845813.svg",
                caption:"Reversionary Bonus"
            },
        ]
        }
    }
    render(){
        return(
            <main style={{backgroundColor:"white"}} className="defaultFont">
                <Logo src="https://s3-reachlocalads.s3.ap-south-1.amazonaws.com/imgs/landing-page/onevaidya/onevaidya_logo.png"/>
                {/* <div> */}
                    <CoverPage src="https://s3-reachlocalads.s3.ap-south-1.amazonaws.com/imgs/landing-page/onevaidya/banner-life-insurance-1.png" width="100%" note="Protect your family's  Financial Future   while you  Stay at Home"/>
                    {/* <Form style={{display: "block",borderRadius:"20px",zIndex:"52",width:"100%",height:"100%"}}/> */}
                {/* </div> */}
                <OrangeLabel />
                <Banner bannerImg={this.state.bannerImg} col="3"/>
                <Bottom src="https://s3-reachlocalads.s3.ap-south-1.amazonaws.com/imgs/landing-page/onevaidya/d4b8e51a-medical-health-insurance-concept-vector-illustration-10045-476_10ay07000000000000001o.jpg" width="394px" height="252px" top="0%" title="Why Smart Lifestyle plan?"/>
                <CopyRight right="Copyright @ Onevaidya. All Rights Reserved."/>
            </main>
        )
    }
} 