import React, { Component } from 'react';
import Popup from '../Popup/Popup';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { ItemMeta } from 'semantic-ui-react';
import utils from '../../Services/utility-service';
import CircularLoader from '../circular-loader/circular-loader';
import { saveInvoiceStatus, getInvoiceStatus, editInvoiceStatus } from '../../Services/shortUrl-service';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import RevenueInvoiceStatus from './RevenueInvoiceStatus';
import InvoiceStatusPopup from './InvoiceStatusPopup';
import DatePicker from "react-datepicker";

const initialState = {
    info: null,
    itemformControl: null,
    invoiceData: "--",
    details: false,
    addData: false,
    editData: false,
    popUp: false,
}

export default class RevenueTable extends Component {

    constructor(props) {
        super(props);
        this.state = initialState;
        this.bChangeHandler = this.bChangeHandler.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.addNew = this.addNew.bind(this);
        this.editExisting = this.editExisting.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.toggle = this.toggle.bind(this);


    }

    componentDidMount() {
        //this.getInvoiceDetails();
    }



    editDetails(item) {
        console.log("item", item);
        console.log();

        let temp = item;
        //temp[invoiceId] = item.invoiceId;
        //temp.agencyBusinessUid = item.agencyBusinessUid;
        //temp.clientBusinessUid = item.clientBusinessUid;
        /* temp.invoiceNumber = this.state.invoiceData.invoiceNumber;
         temp.agencyPaymentDate = this.state.invoiceData.agencyPaymentDate;
         temp.agencyPaymentReferenceNumber = this.state.invoiceData.agencyPaymentReferenceNumber;
         temp.invoiceLink = this.state.invoiceData.invoiceLink;
         temp.agencyPaymentDate = this.state.invoiceData.agencyPaymentDate;
         temp.remarks = this.state.invoiceData.remarks;
         */


        this.setState({
            details: true,
            info: item,
            itemformControl: item,
            popUp: true,
        });

        console.log("temp data", temp, "info data ", this.state.info);

    }

    /*saveDetails() {
        console.log("item", this.state.info);
        console.log("Saved success");

        const body = {
            invoiceId: this.state.info.invoiceId,
            agencyPaymentDate: this.state.info.agencyPaymentDate,
            agencyPaymentReferenceNumber: this.state.info.agencyPaymentReferenceNumber,
            invoiceNumber: this.state.info.invoiceNumber,
            invoiceLink: this.state.info.invoiceLink,
            agencyBusinessUid: this.state.info.agency.uid,
            clientBusinessUid: this.state.info.client.uid,
            invoiceStatus: this.state.info.invoiceStatus,
            remarks: this.state.info.remarks,
        }
        saveInvoiceStatus(body)
            .then(response => response.json())
            .then(data => {
                if (data.success) {

                    console.log("Inside add data to backend", this.state.info.agencyPaymentDate,
                        this.state.info.paymentRefernceNumber,
                        this.state.info.agencyInvoiceNumber, this.state.info.agencyInvoiceLink, this.state.info.agency.name,
                        this.state.info.client.name, this.state.info.remarks);
                    ToastsStore.success("Details Saved Successfully !");


                } else {
                    ToastsStore.error(data.message);
                }
            }).catch(error => {
                console.log(error);
                ToastsStore.error("Something went wrong, Please Try Again Later ");
            })

        console.log("add new invoice status", body);



    }
    */

    editInvoice() {
        console.log("item", this.state.info);
        console.log("Saved success");

        const body = {
            rslId:this.state.info.rslId,
            invoiceId: this.state.info.invoiceId,
            agencyPaymentDate: this.state.info.agencyPaymentDate,
            agencyPaymentReferenceNumber: this.state.info.agencyPaymentReferenceNumber,
            invoiceNumber: this.state.info.invoiceNumber,
            invoiceLink: this.state.info.invoiceLink,
            agencyBusinessUid: this.state.info.agency.uid,
            clientBusinessUid: this.state.info.client.uid,
            revenueStatus: this.state.info.revenueStatus,
            remarks: this.state.info.remarks,
        }
        editInvoiceStatus(body)
            .then(response => response.json())
            .then(data => {
                if (data.success) {

                    console.log("Inside edit existing data to backend", this.state.info.agencyPaymentDate,
                        this.state.info.agencyPaymentRefernceNumber,
                        this.state.info.invoiceNumber, this.state.info.invoiceLink, this.state.info.agency.name,
                        this.state.info.client.name, this.state.info.remarks);
                    ToastsStore.success("Details Saved Successfully !");
                    console.log("data",data);


                } else {
                    ToastsStore.error(data.message);
                }
            }).catch(error => {
                console.log(error);
                ToastsStore.error("Something went wrong, Please Try Again Later ");
            })

        console.log("edit invoice status", body);



    }


    /*
        getInvoiceDetails() {
    
            const body = {
                invoiceId: this.props.revenueHis.invoiceId,
                agencyBusinessUid: "70003160600",
                clientBusinessUid: "70009161176",
                //invoiceStatus:"PAID",
            }
    
            getInvoiceStatus(body)
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
    
                        console.log("Get invoice status", this.state.info.invoiceId, this.state.info.agency.uid, this.state.info.client.uid,);
                        ToastsStore.success("Success");
                        this.setState({
                            invoiceData: data,
    
    
                        })
                        /* let temp = this.state.item;
                         temp.invoiceNumber = data.invoiceNumber;
                         temp.agencyPaymentDate = data.agencyPaymentDate,
                             temp.agencyPaymentReferenceNumber = data.agencyPaymentReferenceNumber,
                             temp.invoiceLink = data.invoiceLink,
                             temp.agencyPaymentDate = data.agencyPaymentDate,
                             temp.remarks = data.remarks,
    
                        console.log("invoiceData", this.state.invoiceData, "fetched data", data);
    
    
                    } else {
                        ToastsStore.error(data.message);
                    }
                }).catch(error => {
                    console.log(error);
                    ToastsStore.error("Something went wrong, Please Try Again Later ");
                })
    
        }
        */

    bChangeHandler(event) {
        //setInfo(itemformControl);
        let name = event.target.name;
        let val = event.target.value;
        let temp = this.state.info;
        temp[name] = val;

        this.setState({
            info: temp,
        })
        console.log("bhandler", temp);
        console.log("State-hndlr", this.state.info);
    }

    handleSelectChange(event) {
        //setInfo(itemformControl);

        let name = 'revenueStatus';
        let value = event.target.value;
        let temp = this.state.info;
        temp[name] = value;
        this.setState({
            info: temp,
        })
        console.log("temp", temp, "value", this.state.info);

    }

    handleDateChange(event) {

        let name = 'agencyPaymentDate';
        let value = event;
        let temp = this.state.info;
        temp[name] = value;
        this.setState({
            info: temp,
        })
        console.log("temp", temp, "value", this.state.info);

    }

    addNew() {
        this.setState({
            addData: true,
            popUp: false
        })
        console.log("inside add new data..");

    }

    editExisting() {
        this.setState({
            editData: true,
            popUp: false,
        })
        console.log("inside edit data..");
    }

    closePopup() {

        this.setState({
            details: false,
            info: null,
            addData: false,
            editData: false,

        });
        
    }

    toggle() {
        this.setState({
            popUp: false,
            details:false,
        });
    }



    render() {

        return (
            <React.Fragment>
                {!this.state.details &&
                    <section>
                        <div className="margin-top leads-table-wrapper"
                            style={utils.isMobile ? { maxHeight: "50vh" } : { maxHeight: "70vh" }}
                        >
                            <table className="client">
                                <thead>
                                    <tr>
                                        <th>Total Amount</th>
                                        <th>Transaction Amount</th>
                                        {
                                            utils.isSuAdmin &&
                                            <React.Fragment>
                                                <th>Agency</th>
                                            </React.Fragment>
                                        }
                                        <th>Client</th>
                                        <th>Client Email</th>
                                        <th>Payment Date</th>
                                        <th>Remarks</th>
                                        <th>Status</th>
                                        <th>Agency Payment Date</th>
                                        <th>Agency Invoice Number</th>
                                        <th>Agency Invoice Link</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.revenueHis && this.props.revenueHis.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.amount}</td>
                                                    <td>{item.txnAmount}</td>
                                                    {
                                                        utils.isSuAdmin &&
                                                        <React.Fragment>
                                                            <td>{item.agency.name}</td>
                                                        </React.Fragment>
                                                    }
                                                    <td>{item.client.name}</td>
                                                    <td>{item.client.email}</td>
                                                    {/* <td>{item.created}</td> */}
                                                    <td><Moment format="YYYY/MM/DD">{item.paymentDate}</Moment></td>
                                                    <td>{item.remarks}</td>
                                                    <td>{item.revenueStatus}</td>
                                                    <td>{item.agencyPaymentDate}</td>
                                                    <td>{item.invoiceNumber}</td>
                                                    <td>{item.invoiceLink}</td>

                                                    <td>
                                                        <button onClick={() => this.editDetails(item)} className="ui icon right labeled tiny button">
                                                            <i aria-hidden="true" className="right arrow icon"></i>
                                                            Actions
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                            {
                                false &&
                                <div className="margin-btm margin-top">
                                    <CircularLoader stroke={"#0c73a5"} size={"36"} buttonSize={"50px"}></CircularLoader>
                                </div>
                            }
                            {
                                this.props.revenueHis.length === 0 &&
                                <div className="padding-top padding-btm" style={{ textAlign: 'center', fontSize: 'small' }}>
                                    No Data to show
                                </div>
                            }


                        </div>


                    </section>
                }

                {utils.isSuAdmin &&
                    this.state.details && this.state.addData &&
                    <React.Fragment>

                        <div>
                            <article className="">
                                <div className="align-space-between col-20 flex flex--wrap flex-direction--row margin-top">
                                    <div className="col-6">
                                        <label className="label col-5">Total Amount</label>
                                        <div className="" >{this.state.info.amount}</div>

                                    </div>
                                    <div className="col-6">
                                        <label className="label col-5">Transaction Amount</label>
                                        <div className="" >{this.state.info.txnAmount}</div>

                                    </div>
                                    {utils.isSuAdmin &&
                                        <div className="col-6">
                                            <label className="label col-5">Agency</label>
                                            <div className="" >{this.state.info.agency.name}</div>

                                        </div>
                                    }
                                    <div className="col-6">
                                        <label className="label col-5">Client</label>
                                        <div className="" >{this.state.info.client.name}</div>

                                    </div>
                                    <div className="col-8">
                                        <label className="label col-5">Client Email</label>
                                        <div className="" >{this.state.info.client.email}</div>

                                    </div>
                                </div>
                                <div className="align-space-between col-20 flex flex--wrap flex-direction--row margin-top">
                                    <div className="col-6">
                                        <label className="label col-5">Payment Date</label>
                                        <div className="" ><Moment format="YYYY/MM/DD">{this.state.info.paymentDate}</Moment></div>

                                    </div>
                                    <div className="col-6">
                                        <label className="label col-5">Remarks</label>

                                        <input type="text"
                                            className="form-control col-20"
                                            name="remarks"
                                            defaultValue={this.state.info.remarks}
                                            onChange={this.bChangeHandler}
                                            placeholder="Remarks" />

                                    </div>
                                </div>
                                <div className="align-space-between col-20 flex flex--wrap flex-direction--row margin-top">
                                    <div className="col-6">
                                        <label className="label col-5">Status</label>
                                        <div>
                                            <select
                                                name="revenueStatus"
                                                value={this.state.info.revenueStatus}
                                                onChange={this.handleSelectChange}>
                                                <option value="UNPAID" defaultValue hidden>Unpaid</option>
                                                <option value="UNPAID">Unpaid</option>
                                                <option value="PAID">Paid</option>
                                                <option value="CANCELLED">Cancelled</option>
                                            </select>
                                        </div>

                                    </div>
                                    <div className="col-6">
                                        <label className="label col-5">Agency Payment Date</label>
                                        {/*<DatePicker
                                        selected={info.agencyPaymentDate}
                                        placeholderText="Click to select Date"
                                       
                                        onChange={handleDateChange}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        className="col-20"
                                        name="agencyPaymentDate"
                            />*/}
                                        <div>
                                            <input type="text"
                                                className="form-control col-20"
                                                name="agencyPaymentDate"
                                                defaultValue={this.state.info.agencyPaymentDate}
                                                //value={item.agencyPaymentDate}
                                                onChange={this.bChangeHandler}
                                                placeholder="Agency Payment Date" />
                                        </div>


                                    </div>

                                    <div className="col-6">
                                        <label className="label col-5">Agency Payment Reference Number</label>
                                        <div>
                                            <input type="text"
                                                className="form-control col-20"
                                                name="paymentReferenceNumber"
                                                defaultValue={this.state.info.agencyPaymentReferenceNumber}
                                                //value={item.paymentReferenceNumber}
                                                onChange={this.bChangeHandler}
                                                placeholder="Agency Payment Reference Number" />
                                        </div>

                                    </div>
                                </div>
                                <div className="align-space-between col-20 flex flex--wrap flex-direction--row margin-top">
                                    <div className="col-6">
                                        <label className="label col-5">Agency Invoice Number</label>
                                        <div>
                                            <input type="text"
                                                className="form-control col-20"
                                                name="invoiceNumber"
                                                defaultValue={this.state.info.invoiceNumber}
                                                //value={item.agencyInvoiceNumber}
                                                onChange={this.bChangeHandler}
                                                placeholder="Agency Invoice Number" />
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <label className="label col-5">Agency Invoice Link</label>
                                        <div>
                                            <input type="text"
                                                className="form-control col-20"
                                                name="invoiceLink"
                                                defaultValue={this.state.info.invoiceLink}
                                                //value={item.agencyInvoiceLink}
                                                onChange={this.bChangeHandler}
                                                placeholder="Agency Invoice Link" />
                                        </div>
                                    </div>
                                </div>


                            </article>
                            <div className="dialog-footer pad margin-top">

                                <button className="ui green button" onClick={() => this.saveDetails()}>
                                    ADD NEW
                                </button>

                                <button
                                    className="btn btn-fill dialog--cta pointer"
                                    onClick={() => this.closePopup()}>
                                    Close
                                </button>

                            </div>

                        </div>


                    </React.Fragment>
                }

                {utils.isSuAdmin &&
                    this.state.details && this.state.editData &&
                    <React.Fragment>

                        <div>
                            <article className="">
                                <div className="align-space-between col-20 flex flex--wrap flex-direction--row margin-top">
                                    <div className="col-6">
                                        <label className="label col-5">Total Amount</label>
                                        <div className="" >{this.state.info.amount}</div>

                                    </div>
                                    <div className="col-6">
                                        <label className="label col-5">Transaction Amount</label>
                                        <div className="" >{this.state.info.txnAmount}</div>

                                    </div>
                                    {utils.isSuAdmin &&
                                        <div className="col-6">
                                            <label className="label col-5">Agency</label>
                                            <div className="" >{this.state.info.agency.name}</div>

                                        </div>
                                    }
                                    <div className="col-6">
                                        <label className="label col-5">Client</label>
                                        <div className="" >{this.state.info.client.name}</div>

                                    </div>
                                    <div className="col-8">
                                        <label className="label col-5">Client Email</label>
                                        <div className="" >{this.state.info.client.email}</div>

                                    </div>
                                </div>
                                <div className="align-space-between col-20 flex flex--wrap flex-direction--row margin-top">
                                    <div className="col-6">
                                        <label className="label col-5">Payment Date</label>
                                        <div className="" ><Moment format="YYYY/MM/DD">{this.state.info.paymentDate}</Moment></div>

                                    </div>
                                    <div className="col-6">
                                        <label className="label col-5">Remarks</label>

                                        <input type="text"
                                            className="form-control col-20"
                                            name="remarks"
                                            defaultValue={this.state.info.remarks}
                                            onChange={this.bChangeHandler}
                                            placeholder="Remarks" />

                                    </div>
                                </div>
                                <div className="align-space-between col-20 flex flex--wrap flex-direction--row margin-top">
                                    <div className="col-6">
                                        <label className="label col-5">Status</label>
                                        <div>
                                            <select
                                                name="revenueStatus"
                                                value={this.state.info.revenueStatus}
                                                onChange={this.handleSelectChange}>
                                                <option value="UNPAID" defaultValue hidden>Unpaid</option>
                                                <option value="UNPAID">Unpaid</option>
                                                <option value="PAID">Paid</option>
                                                <option value="CANCELLED">Cancelled</option>
                                            </select>
                                        </div>

                                    </div>
                                    <div className="col-6">
                                        <label className="label col-5">Agency Payment Date</label>
                                        {/*<DatePicker
                                        selected={info.agencyPaymentDate}
                                        placeholderText="Click to select Date"
                                       
                                        onChange={handleDateChange}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        className="col-20"
                                        name="agencyPaymentDate"
                            />*/}
                                        <div>
                                            <input type="text"
                                                className="form-control col-20"
                                                name="agencyPaymentDate"
                                                defaultValue={this.state.info.agencyPaymentDate}
                                                //value={item.agencyPaymentDate}
                                                onChange={this.bChangeHandler}
                                                placeholder="Agency Payment Date" />
                                        </div>


                                    </div>

                                    <div className="col-6">
                                        <label className="label col-5">Agency Payment Reference Number</label>
                                        <div>
                                            <input type="text"
                                                className="form-control col-20"
                                                name="agencyPaymentReferenceNumber"
                                                defaultValue={this.state.info.agencyPaymentReferenceNumber}
                                                //value={item.paymentRefernceNumber}
                                                onChange={this.bChangeHandler}
                                                placeholder="Agency Payment Reference Number" />
                                        </div>

                                    </div>
                                </div>
                                <div className="align-space-between col-20 flex flex--wrap flex-direction--row margin-top">
                                    <div className="col-6">
                                        <label className="label col-5">Agency Invoice Number</label>
                                        <div>
                                            <input type="text"
                                                className="form-control col-20"
                                                name="invoiceNumber"
                                                defaultValue={this.state.info.invoiceNumber}
                                                //value={item.agencyInvoiceNumber}
                                                onChange={this.bChangeHandler}
                                                placeholder="Agency Invoice Number" />
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <label className="label col-5">Agency Invoice Link</label>
                                        <div>
                                            <input type="text"
                                                className="form-control col-20"
                                                name="invoiceLink"
                                                defaultValue={this.state.info.invoiceLink}
                                                //value={item.agencyInvoiceLink}
                                                onChange={this.bChangeHandler}
                                                placeholder="Agency Invoice Link" />
                                        </div>
                                    </div>
                                </div>


                            </article>
                            <div className="dialog-footer pad margin-top">

                                <button className="ui green button" onClick={() => this.editInvoice()}>
                                    SAVE CHANGES
                                </button>

                                <button
                                    className="btn btn-fill dialog--cta pointer"
                                    onClick={() => this.closePopup()}>
                                    Close
                                </button>

                            </div>

                        </div>


                    </React.Fragment>
                }



                {utils.isSuAdmin && this.state.details && this.state.popUp &&
                    <Popup title={"ADD/EDIT"} togglePopup={this.toggle} >
                        <div className="dialog-footer pad">
                            <button className="ui button" onClick={this.toggle}>
                                BACK
                            </button>

                            <button className="ui green button" onClick={this.editExisting}>
                                VIEW DETAILS
                            </button>
                        </div>

                    </Popup>
                }
            </React.Fragment>
        );
    }
}



