import React, { Component } from 'react';
import utils from '../../Services/utility-service';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import {editConsumedCredits} from '../../Services/subscriptions-service';
// import CircularLoader from '../../circular-loader/circular-loader';



const initialState = {
    showPkgDetail: true,
    pkgDetail: null,

}
export default class ManagePackageDetail extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.editDetails = this.editDetails.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.savePkgDetail = this.savePkgDetail.bind(this);
        this.bChangeHandler = this.bChangeHandler.bind(this);
    }

    editDetails(item) {
        this.setState({
            showPkgDetail: false,
            pkgDetail: item,
        });
        console.log("item", item);
        console.log("pkgdetail", this.state.pkgDetail);

    }

    savePkgDetail() {
        if (this.state.pkgDetail.consumedCredit > this.state.pkgDetail.availableCredit) {
            ToastsStore.error("Availabe Credits are less!! Please enter a different amount");
        }
        if (this.state.pkgDetail.consumedCredit <= this.state.pkgDetail.availableCredit) {
            console.log("inside save", this.state.pkgDetail);

            console.log("item", this.state.info);
            console.log("Saved success");

            const body = {
                id: this.state.pkgDetail.id,
                count: this.state.pkgDetail.consumedCredit,
            }

            editConsumedCredits(body)
            .then(response => response.json())
            .then(data => {
                if (data.success) {

                    console.log("Inside edit existing data to backend", this.state.pkgDetail.id,
                     this.state.pkgDetail.count, this.state.pkgDetail.consumedCredit);
                    ToastsStore.success("Details Saved Successfully !");
                    console.log("data",data);


                } else {
                    ToastsStore.error(data.message);
                }
            }).catch(error => {
                console.log(error);
                ToastsStore.error("Something went wrong, Please Try Again Later ");
            })

        console.log("edit subscription status", body);
        }
    }

    closePopup() {
        this.setState({
            showPkgDetail: true,
        })
    }

    bChangeHandler(event) {
        let name = event.target.name;
        let val = event.target.value;
        let temp = this.state.pkgDetail;
        temp[name] = val;

        this.setState({
            pkgDetail: temp,
        })
        console.log("bhandler", temp);
        console.log("State-hndlr", this.state.pkgDetail);

    }

    render() {

        return (
            <React.Fragment>
                {this.state.showPkgDetail &&
                    <section className={`${utils.isMobile ? "flex-direction--col" : "flex-direction--row"} flex`}>

                        <article className="card-custom leads-table-wrapper mar--half margin-btm pad--half" style={utils.isMobile ? { maxHeight: "60vh", border: '1px solid #eeeeee', borderRadius: '12px', alignItems: 'center' } : { maxHeight: "70vh", border: '1px solid #eeeeee', borderRadius: '12px', alignItems: 'center' }}>
                            <table className="client">
                                <thead>
                                    <tr className="text--center">
                                        <th>Service Name</th>
                                        <th>Unit Type</th>
                                        <th>Assigned Credit</th>
                                        <th>Total Price</th>
                                        <th>Per Unit Price</th>
                                        <th>Available Credit</th>
                                        <th>Consumed Credit</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.listArr && this.props.listArr.subscriptionServiceMappings && this.props.listArr.subscriptionServiceMappings.map((item, index) => {
                                            return (
                                                <tr key={index} className="text--center">
                                                    <td><b>{item.serviceName ? item.serviceName : "--"}</b></td>
                                                    <td>{item.creditUnitType ? item.creditUnitType : "--"}</td>
                                                    <td>{item.assignedCredit ? item.assignedCredit : "--"}</td>
                                                    <td>&#8377;{item.price ? item.price : "--"}</td>
                                                    <td>&#8377;{item.perUnitPrice ? item.perUnitPrice : "--"}</td>
                                                    <td>{item.availableCredit ? item.availableCredit : "--"}</td>
                                                    <td>{item.consumedCredit ? item.consumedCredit : "--"}</td>
                                                    {utils.isSuAdmin &&
                                                        <td>
                                                            <button onClick={() => this.editDetails(item)} className="ui icon right labeled tiny button">
                                                                <i aria-hidden="true" className="right arrow icon"></i>
                                                                Actions
                                                            </button>
                                                        </td>
                                                    }
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </article>
                        {/* <section className="dialog-footer pad">  */}
                        <article className={`${utils.isMobile ? "col-20 margin-top-five" : "col-6 margin-left"} flex flex-direction--col card-custom flex-wrap leadDetail-card pad--half margin-right--half`} style={{ border: '1px solid #eeeeee', borderRadius: '12px', alignItems: 'center' }}>
                            <div className="bdr-btm col-20 flex pad">
                                <h3>Order Summary</h3>
                            </div>
                            <div className="col-20 flex pad bdr-btm flex-space--btw">
                                <div className="text--bold text--darker">Total Amount</div>
                                <div className="text--bold text--darker">&#8377; {this.props.listArr && this.props.listArr.price}</div>
                            </div>
                            <div className="col-20 flex pad bdr-btm flex-space--btw">
                                <div className="text--bold text--darker">Taxable Amount</div>
                                <div className="text--bold text--darker">&#8377; {this.props.listArr && this.props.listArr.taxAmount}</div>
                            </div>
                            <div className="col-20 flex pad bdr-btm flex-space--btw">
                                <div className="text--bold text--darker">Payable Amount</div>
                                <div className="text--bold text--darker"> &#8377; {this.props.listArr && this.props.listArr.totalPrice}</div>
                            </div>
                            {/* <div><button onClick={props.togglePopup} className="btn btn-fill dialog--cta pointer">CLOSE</button> </div> */}
                        </article>


                    </section>
                }
                {!this.state.showPkgDetail &&
                    <React.Fragment>
                        <section className={`${utils.isMobile ? "flex-direction--col" : "flex-direction--row"} flex`}>

                            <article className="card-custom leads-table-wrapper mar--half margin-btm pad--half" style={utils.isMobile ? { maxHeight: "60vh", border: '1px solid #eeeeee', borderRadius: '12px', alignItems: 'center' } : { maxHeight: "70vh", border: '1px solid #eeeeee', borderRadius: '12px', alignItems: 'center', width: '100%' }}>

                                <div className="col-20 flex pad bdr-btm flex-space--btw">
                                    <div className="text--bold text--darker">Service Name</div>
                                    <div className="text--bold text--darker">{this.state.pkgDetail.serviceName && this.state.pkgDetail.serviceName}</div>
                                </div>

                                <div className="col-20 flex pad bdr-btm flex-space--btw">
                                    <div className="text--bold text--darker">Unit Type</div>
                                    <div className="text--bold text--darker">{this.state.pkgDetail.creditUnitType && this.state.pkgDetail.creditUnitType}</div>
                                </div>

                                <div className="col-20 flex pad bdr-btm flex-space--btw">
                                    <div className="text--bold text--darker">Assigned Credit</div>
                                    <div className="text--bold text--darker">{this.state.pkgDetail.assignedCredit && this.state.pkgDetail.assignedCredit}</div>
                                </div>

                                <div className="col-20 flex pad bdr-btm flex-space--btw">
                                    <div className="text--bold text--darker">Total Price</div>
                                    <div className="text--bold text--darker">&#8377; {this.state.pkgDetail.price && this.state.pkgDetail.price}</div>
                                </div>

                                <div className="col-20 flex pad bdr-btm flex-space--btw">
                                    <div className="text--bold text--darker">Per Unit Price</div>
                                    <div className="text--bold text--darker">&#8377; {this.state.pkgDetail.perUnitPrice && this.state.pkgDetail.perUnitPrice}</div>
                                </div>

                                <div className="col-20 flex pad bdr-btm flex-space--btw">
                                    <div className="text--bold text--darker">Available Credit</div>
                                    <div className="text--bold text--darker">{this.state.pkgDetail.availableCredit && this.state.pkgDetail.availableCredit}</div>
                                </div>

                                <div className="col-20 flex pad bdr-btm flex-space--btw">
                                    <div className="text--bold text--darker">Consumed Credit</div>
                                    <div>
                                        <input type="text"
                                            className="form-control col-20"
                                            name="consumedCredit"
                                            defaultValue={this.state.pkgDetail.consumedCredit}
                                            onChange={this.bChangeHandler}
                                            placeholder="Consumed Credit" />
                                    </div>
                                </div>

                                <div className="dialog-footer pad margin-top">

                                    <button className="ui green button" onClick={() => this.savePkgDetail()}>
                                        SAVE CHANGES
                                    </button>

                                    <button
                                        className="btn btn-fill dialog--cta pointer"
                                        onClick={() => this.closePopup()}>
                                        Close
                                    </button>

                                </div>
                            </article>
                        </section>


                    </React.Fragment>
                }
            </React.Fragment>
        );
    }

}