import React from 'react';
import utils from '../../Services/utility-service';
export default function OneCard(props){

    console.log(props.oneCard.parentContent);
    // const [currCard, setCurrCard] = useState(()=>null);

    function handleClick(code){
       
        props.click(code);
        
    }

    // useEffect(()=>{
        
        // setCurrCard(props.currCard);
        // console.log(currCard);
       
    // },[props.currCard])
   
    return(
        <React.Fragment>
        {/* <button  className="ui icon left labeled tiny button" onClick={props.page("null")}><i aria-hidden="true" className="left chevron icon"></i>Back</button>*/}
        <section className={utils.isMobile ? "flex flex-direction--col": "flex"}> 
           {/* { !!props.currCard && */}
            <div className="card-border pad mar">
                <img src={ props.currCard && props.currCard.icon} alt="icon" width="15%"/>
                <p style={utils.isMobile? {fontSize:"1.0rem"}:{fontSize:"1.4rem"}}><b>{props.currCard && props.currCard.title}</b></p>
                <p style={utils.isMobile?{fontSize:"1rem"}:{fontSize:"1.2rem"}}>{props.currCard && props.currCard.text}</p>
            </div>
           {/* }  */}
            
            <div style={{flex:"40%"}}>
                {
                    props.oneCard && props.oneCard.elements && props.oneCard.elements.map((item,index)=>{
                        return(
                            <div key={index} className="dashboard-headline" style={{cursor:"pointer"}}>
                                <p style={utils.isMobile?{fontSize:"0.8rem"}:{fontSize:"large",letterSpacing:"1px"}} className="flex flex-space--btw" onClick={()=>handleClick(item.code)}>
                                    <b>{item.title.toUpperCase()}</b>
                                    <i class="chevron right icon"></i>
                                </p>
                            </div>
                        )
                    })
                }
            </div>
        </section>
        </React.Fragment>
    )
}