import React, { Component } from 'react';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import EmailTemplatesTable from './EmailTemplatesTable';
import CreateEmailTemplate from './CreateEmailTemplate';
import PageTitle from '../../Components/Helmet';
import { getAllEmail, getAllEmailTemplates } from '../../Services/lead-service'
import utils from '../../Services/utility-service';
import Popup from '../../Components/Popup/Popup';


const initialState = {
    emailTemplates: null,
    confirmationLoader: false,
    addNew: false,
}

export default class EmailTemplates extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.addDetails = this.addDetails.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }

    componentDidMount() {
        this.fetchAllEmail();
    }

    fetchAllEmail() {
        let body = {
            start: 0,
            maxResult: 100,
            businessUid: null,
        }
        this.setState({
            confirmationLoader: true
        })
        getAllEmailTemplates(body)
            .then(r => r.json())
            .then(data => {
                if (data.success) {
                    this.setState({
                        emailTemplates: this.formatEmailTempl(data.emailTemplates),
                        confirmationLoader: false
                    })
                    this.toggleEmail();
                    ToastsStore.success(data.message);
                }
                else {
                    ToastsStore.error(data.message);
                }
            })
            .catch(e => {
                ToastsStore.error("!!!");
                this.setState({
                    confirmationLoader: false
                })
            })
    }

    formatEmailTempl(email) {
        email.forEach(e => { e.error = ""; });
        return email;
    }

    addDetails() {
        this.setState({
            addNew: true,
        })
    }

    closePopup() {
        this.setState({
            addNew: false,
        });
    }

    render() {
        return (
            <React.Fragment>
                <main className="wrapper-container">
                    <PageTitle title="SMS Templates" description="Welcome to SMS Templates" />
                    <div className="card-custom flex flex-direction--row flex-wrap pad--half">
                        <h4 className="ui header">Email Templates</h4>
                        <div className="senderId-action--wrapper margin-left--auto">
                            <div className="senderId-action--wrapper margin-left--auto">
                                <button onClick={() => this.addDetails()} className="btn btn-fill btn-success margin-left--auto">New Template</button>
                            </div>
                        </div>
                    </div>
                    <section className="card-custom pad">
                        <div className="margin-top margin-btm">
                        </div>
                        {utils.isSuAdmin &&
                            <EmailTemplatesTable
                                emailTemplate={this.state.emailTemplates}>
                            </EmailTemplatesTable>
                        }
                    </section>
                </main>

                {
                    utils.isSuAdmin && this.state.addNew &&

                    <React.Fragment>
                        <Popup title={'Create New Template'} togglePopup={this.closePopup} >

                        <CreateEmailTemplate
                            closePopup = {this.closePopup}>
                        </CreateEmailTemplate>
                        </Popup>


                    </React.Fragment>
                }
            </React.Fragment>
        );
    }

}