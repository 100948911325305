import React from 'react';
import Accordion from '../Accordion/Accordion';
import {dashboardAPI} from '../../Services/dashboard-service';
import {ToastsContainer,ToastsStore,ToastsContainerPosition} from 'react-toasts';
import Card from './Card';
import utils from '../../Services/utility-service';
import ConnectWithUs from './ConnectWithUs';
import Popup from '../Popup/Popup';



export default class DashboardObjective extends React.Component{

   constructor(props){
       super(props);
       this.state={
           dash:[],
           card:[],
           oneCard:[],
           cardHeading:[],
           layoutType:null,
           prevLayout: null,
           showPopup: false
       }
   }

    componentDidMount(){
       this.fetchDashBoard();
    }

    fetchDashBoard(code){
        let body;
        if(code){
           body ={parentCode: code};
        }
        else{
            body={"parentCode": "ALL"};
        }
        dashboardAPI(body)
        .then(res => res.json())
        .then(data=>{
            if(data.success){
                this.setState(prevState=>{
                    return{
                        layoutType:data.configDTO.layoutType,
                        prevLayout:prevState.layoutType
                    }
                })

                if(code && data.configDTO.layoutType === "CARD"){
                    this.setState({
                        card: data.configDTO
                    }) 
                }
                else if(code && data.configDTO.layoutType === "ONECARD"){
                    this.setState({
                        oneCard: data.configDTO
                    })
                }
                else if(code && data.configDTO.layoutType === "CARDHEADING"){
                    this.setState({
                        cardHeading: data.configDTO
                    })
                }
                else{
                    this.setState({
                        dash: data.configDTO.elements
                    })
                }
            }
            else{
                ToastsStore.error(data.message);
            }
        })
        .catch(e => {
            console.error(e);
            ToastsStore.error("Something went wrong. Please try again later.!!!");
        })
    }

    closeModal(layout){
        this.setState({
            layoutType:layout
        })
    }

    operatePopup(){
        this.setState(state=>{
            return{
                showPopup: !state.showPopup
            }
        })
    }

    render(){
        
    return(
        <section style={{marginTop:"25px"}}>
            <h1 className={utils.isMobile ? "font-size-1rem text--center" : "text--center"}>WHAT's your OBJECTIVE ?</h1>
            {
                this.state.dash && this.state.dash.map((item)=>{
                    return(
                        <Accordion 
                            title={item.title}
                            path={item.icon}
                            width="40px"
                            backgroundColor="white"
                            code={item.code}
                            click={this.fetchDashBoard.bind(this)}
                        >
                            {
                                this.state.card && (this.state.layoutType === "CARD" || this.state.layoutType === "ONECARD" || this.state.layoutType === "CARDHEADING") &&
                                <Card 
                                    card={this.state.card}
                                    operatePopup={this.operatePopup.bind(this)} 
                                    closeModal={this.closeModal.bind(this,"CARD")} 
                                    back={this.closeModal.bind(this,this.state.prevLayout)}
                                    click={this.fetchDashBoard.bind(this)} 
                                    oneCard={this.state.oneCard} 
                                    cardHeading={this.state.cardHeading} 
                                    layoutType={this.state.layoutType}
                                />
                            }  
                        </Accordion>
                    )
                })
            }
            
            {
                this.state.showPopup &&
                <Popup togglePopup={()=>this.setState({showPopup: false})}>
                    <ConnectWithUs />
                </Popup>
            }
            <ToastsContainer position={ToastsContainerPosition.TOP_RIGHT} lightBackground store={ToastsStore} />
        </section>
    )
        }
}