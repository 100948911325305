const serverOrigin = window.location.origin;

async function addIdPasswordDetails(email,userName,password,mobile,serviceType,businessUid,apiKey){
 
    const response =
      await fetch( serverOrigin+"/operation/create/external/service/mapping?userName="+userName+"&password="+password+"&businessUid="+businessUid+"&serviceType="+serviceType+"&mobile="+mobile+"&email="+email+"&apiKey="+apiKey,
        { 
          headers: {'Content-Type': 'application/json','Accept': 'application/json'}
        }
      )
    return await response;
  }

  async function editIdPasswordDetails(email,userName,password,mobile,serviceType,businessUid){
    const response =
      await fetch( serverOrigin+"/operation/edit/external/service/mapping?userName="+userName+"&password="+password+"&businessUid="+businessUid+"&serviceType="+serviceType+"&mobile="+mobile+"&email="+email,
        { 
          method: 'post',
          headers: {'Content-Type': 'application/json','Accept': 'application/json'}
        }
      )
    return await response;
  }
/*async function editIdPasswordDetails(userName,password,businessUid,serviceType){
    const response =
      await fetch( "http://13.233.125.97:8080/operation/edit/external/service/mapping?userName=abhishekmajhi&password=1234rambaburau&businessUid=70009162285&serviceType=wapp",
        { 
          method: 'post',
          headers: {'Content-Type': 'application/json','Accept': 'application/json'}
        }
      )
    return await response;
  }*/
  export {addIdPasswordDetails, editIdPasswordDetails}