import React from 'react';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';



export default function AddLead(props){
   
    

    return(
        <main>
            <form className="senderId-modal--wrapper" onSubmit={props.submitLead}>
                <div className="col-9 field">
                    <label htmlFor="name"><b>Name</b></label>
                    <input value={props.leadArr.name} type="text" id="name" className="form-control" name="name" onChange={props.changeHandler}/>
                </div>
                <div className="col-9 margin-left--auto">
                    <label htmlFor="mobile" className="required"><b>Contact Number</b></label>
                    <div className="ui labeled input">
                        <div className="ui label label">+91</div>
                        <input style={{width:"80%"}} id="mobile" inputMode="numeric" pattern="[0-9]*" className="form-control" name="mobile" type="number" onChange={props.changeHandler} required/>
                    </div>
                </div>  
                <div className="col-9 margin-top--half required field">
                    <label htmlFor="address"><b>Address</b></label>
                    <input id="address" name="address" className="form-control" onChange={props.changeHandler}/>
                </div>    
                <div className="col-9 margin-top--half margin-left--auto">
                    <label htmlFor="email"><b>Email Id</b></label>
                    <input id="email"  name="email" type="email" className="form-control" onChange={props.changeHandler}/>
                </div>
                <div className="col-9 margin-top--half">
                    <label htmlFor="city"><b>City</b></label>
                    <input id="city" name="city" className="form-control"onChange={props.changeHandler} />
                </div>
                <div className="col-9 margin-top--half margin-left--auto required field">
                    <label htmlFor="campaignId" className="required"><b>Campaign Name&nbsp;</b></label>
                     {/* <div> */}
                         {/* <input id="campaignName" name="campaignId" type="text" className="form-control" />  */}
                        <select style={{width:"90%"}} id="campaignId" name="campaignId" className="form-control" onChange={props.changeHandler} required>
                            <option value="" hidden>--Choose Campaign--</option>
                            {
                                props.campaigns.map((item,index) => {
                                    return(
                                        <option key={index} value={item.id}>{item.name}</option>
                                    );
                                })
                            }
                        </select>
                    {/* </div> */}
                </div> 
                <div className="col-9 margin-top--half">
                    <label htmlFor="state"><b>State</b></label>
                    <input id="state" name="state" className="form-control" onChange={props.changeHandler}/>
                </div>
                <div className="col-9 margin-top--half margin-left--auto required field">
                    <label htmlFor="source"><b>Source</b></label>
                    <input id="source" name="source" className="form-control" onChange={props.changeHandler} srequired/>
                </div>
                <div className="col-9 margin-top--half">
                    <label htmlFor="pincode"><b>PIN Code</b></label>
                    <input type="number" id="pincode" name="pincode" className="form-control" onChange={props.changeHandler}/>   
                </div>
                <div className="col-9 margin-top--half margin-left--auto">
                    <label htmlFor="pubId"><b>Publisher Id/Vendor</b></label>
                    {/* <div><input id="pubId" name="pubId" type="text" className="form-control" /></div> */}
                    <select style={{width:"90%"}} id="pubId" name="pubId" className="form-control" onChange={props.changeHandler}>
                        <option value="" hidden>--Choose Publisher Id/Vendor--</option>
                        {
                            props.publIds.map((item,index) => {
                                return(
                                    <option key={index} value={item.publisherId}>{item.businessName}&nbsp;{!!item.publisherId ? ' - '+item.publisherId: ''}</option>
                                );
                            })
                        }
                    </select>
                </div>
                <div className="col-9 margin-top--half">
                    <label htmlFor="s1"><b>s1</b></label>
                    <input id="s1" name="s1" className="form-control" onChange={props.changeHandler}/>  
                </div>
                <div className="col-9 margin-top--half margin-left--auto">
                    <label htmlFor="s2"><b>s2</b></label>
                    <input id="s2" name="s2" className="form-control" onChange={props.changeHandler}/>        
                </div>
                <div className="col-9 margin-top--half">
                    <label htmlFor="s3"><b>s3</b></label>
                    <input id="s3" name="s3" className="form-control" onChange={props.changeHandler}/> 
                </div>
                <div className="col-9 margin-top--half margin-left--auto">
                    <label htmlFor="s4"><b>s4</b></label>
                    <input id="s4" name="s4" className="form-control" onChange={props.changeHandler}/>
                </div> 
                <div className="col-9 margin-top--half">
                    <label htmlFor="transactionId"><b>Transaction ID</b></label>
                    <input id="transactionId" name="transactionId" className="form-control" onChange={props.changeHandler}/> 
                </div>
                <div className="col-9 margin-top--half margin-left--auto">
                    <label htmlFor="medium"><b>Medium</b></label>
                    <input id="medium" name="medium" className="form-control" onChange={props.changeHandler}/>
                </div>
                <div className="col-9 margin-top--half">
                    <label htmlFor="age"><b>Age</b></label>
                    <input id="age" name="age" type="number" className="form-control" onChange={props.changeHandler}/> 
                </div>    
                <div className="col-9 margin-top--half margin-left--auto">
                    <label htmlFor="tags"><b>Tags</b></label>
                    <input id="tags" name="tags" className="form-control" onChange={props.changeHandler}/>
                </div>        
            
            </form>

            <div className="text--center" style={{color:"red"}}>{props.leadArr.error}</div>
           
                <ConfirmationModal 
                submitCta="Submit"
                togglePopup={props.togglePopup}
                submitData={props.submitLead}
                confirmationLoader={props.confirmationLoader}
            />
           
           
        </main>
    )
}