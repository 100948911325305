import React, { Component } from 'react';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import SvgIcon from '../../Components/Svg-icon/Svg-icon';
import Popup from '../../Components/Popup/Popup';
import utils from '../../Services/utility-service';
import { addEditEmailTemplates } from '../../Services/lead-service'

const initialState = {
    view: false,
    edit: false,
    templateDetails: null,
    actions: ["edit"]
}

export default class EmailTemplatesTable extends Component {

    constructor(props) {
        super(props);
        this.state = initialState;
        this.editDetails = this.editDetails.bind(this);
        this.closeAction = this.closeAction.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
    }

    editDetails(item) {
        this.setState({
            edit: true,
            templateDetails: item,
        });
        console.log("item", item);
        console.log("template state", this.state.templateDetails);
    }

    submitData() {
        console.log("inside edit data");
        console.log("Saved success");

        const body = {
            emailTemplateId: this.state.templateDetails.templateId,
            subject: this.state.templateDetails.subjectTemplate,
            templateBody: this.state.templateDetails.bodyTemplate,
            emailType: this.state.templateDetails.type,
            name: this.state.templateDetails.name,
            fromEmail: this.state.templateDetails.fromEmail,
            fromName: this.state.templateDetails.fromName,
            noreplyEmail: this.state.templateDetails.replyTo,
            emailChannelId: this.state.templateDetails.emailChannelId,
        }

       /* addEditEmailTemplates(body)
            .then(response => response.json())
            .then(data => {
                if (data.success) {

                    console.log("Inside edit existing data to backend", this.state.templateDetails.name, this.state.templateDetails.bodyTemplate,
                    this.state.templateDetails.subjectTemplate, this.state.templateDetails.to, this.state.templateDetails.cc, 
                    this.state.templateDetails.bcc, this.state.templateDetails.type);
                    ToastsStore.success("Details Saved Successfully !");
                    console.log("data",data);


                } else {
                    ToastsStore.error(data.message);
                }
            }).catch(error => {
                console.log(error);
                ToastsStore.error("Something went wrong, Please Try Again Later ");
            })*/

        console.log("edit email template", body);

    }

    changeHandler(event) {
        let name = event.target.name;
        let val = event.target.value;
        let temp = this.state.templateDetails;
        temp[name] = val;

        this.setState({
            templateDetails: temp,
        })
        console.log("bhandler", temp);
        console.log("State-hndlr", this.state.templateDetails);
    }


    closeAction() {
        this.setState({
            view: false,
            edit: false,
        })
    }


    render() {
        return (
            <React.Fragment>
                <section className="card-custom padding-btm--half leads-table-wrapper"
                    style={utils.isMobile ? { maxHeight: "60vh" } : { maxHeight: "70vh" }}
                >
                    <table className="client">
                        <thead>
                            <tr>
                                <th>Template Id</th>
                                <th>Name</th>
                                <th>Body Template</th>
                                <th>Subject Template</th>
                                <th>To</th>
                                <th>Cc</th>
                                <th>Bcc</th>
                                <th>From Name</th>
                                <th>From Email</th>
                                <th>Reply To</th>
                                <th>Email Channel Id</th>
                                <th>Category</th>
                                <th>Type</th>
                                <th>Actions</th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.emailTemplate && this.props.emailTemplate.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.templateId}</td>
                                            <td>{item.name}</td>
                                            <td>{item.bodyTemplate}</td>
                                            <td>{item.subjectTemplate}</td>
                                            <td>{item.to}</td>
                                            <td>{item.cc}</td>
                                            <td>{item.bcc}</td>
                                            <td>{item.fromName}</td>
                                            <td>{item.fromEmail}</td>
                                            <td>{item.replyTo}</td>
                                            <td>{item.emailChannelId}</td>
                                            <td>{item.catergory}</td>
                                            <td>{item.type}</td>


                                            <td>

                                            <button onClick={() => this.editDetails(item)} className="ui icon right labeled tiny button">
                                                            <i aria-hidden="true" className="right arrow icon"></i>
                                                            Actions
                                                        </button>

                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>

                    {this.state.edit &&
                        <React.Fragment>
                            <Popup title={'Edit Template'} togglePopup={this.closeAction} >
                                <section>
                                    <div className="senderId-modal--wrapper">
                                        <div className="col-9">
                                            <div className="label">Name</div>
                                            <input type="text"
                                                className="form-control"
                                                name="name"
                                                defaultValue={this.state.templateDetails.name}
                                                onChange={this.changeHandler}
                                            />
                                        </div>

                                        <div className="col-9">
                                            <div className="label">Body Template</div>
                                            <input type="text"
                                                className="form-control"
                                                name="bodyTemplate"
                                                defaultValue={this.state.templateDetails.bodyTemplate}
                                                onChange={this.changeHandler}
                                            />
                                        </div>

                                        <div className="col-9">
                                            <div className="label">Subject Template</div>
                                            <input type="text"
                                                className="form-control"
                                                name="subjectTemplate"
                                                defaultValue={this.state.templateDetails.subjectTemplate}
                                                onChange={this.changeHandler}
                                            />
                                        </div>

                                        <div className="col-9">
                                            <div className="label">To</div>
                                            <input type="text"
                                                className="form-control"
                                                name="to"
                                                defaultValue={this.state.templateDetails.to}
                                                onChange={this.changeHandler}
                                            />
                                        </div>

                                        <div className="col-9">
                                            <div className="label">Cc</div>
                                            <input type="text"
                                                className="form-control"
                                                name="cc"
                                                defaultValue={this.state.templateDetails.cc}
                                                onChange={this.changeHandler}
                                            />
                                        </div>

                                        <div className="col-9">
                                            <div className="label">Bcc</div>
                                            <input type="text"
                                                className="form-control"
                                                name="bcc"
                                                defaultValue={this.state.templateDetails.bcc}
                                                onChange={this.changeHandler}
                                            />
                                        </div>

                                        <div className="col-9">
                                            <div className="label">Type</div>
                                            <input type="text"
                                                className="form-control"
                                                name="type"
                                                defaultValue={this.state.templateDetails.type}
                                                onChange={this.changeHandler}
                                            />
                                        </div>

                                        <div className="dialog-footer pad">
                                            <div>
                                                <button className="btn btn-fill dialog--cta pointer" onClick={() => this.closeAction()}>
                                                    Back
                                                </button>
                                                <button onClick={() => this.submitData()} className="btn btn-fill btn-success margin-left--half dialog--cta pointer">Save Changes</button>
                                            </div>
                                        </div>


                                    </div>
                                </section>
                            </Popup>

                        </React.Fragment>
                    }
                </section>
            </React.Fragment>
        );
    }

}