import React from 'react';
import { useEffect, useState } from 'react';
import serviceTypes from '../../Constants/service-types.constants';
import serviceTypesAdd from '../../Constants/service-types.constants';
import CircularLoader from '../../Components/circular-loader/circular-loader';
import Select from 'react-select';
import '../../Containers/Profile/Profile.css';




export default function IdPasswordDetails(props) {

    const [panelName, setPanelName] = useState(null);

    function saveDetails() {

        props.saveCredentialsDetails();

    }

    function handleSelectChange(e) {


        if (e.value === "wapp") {
            setPanelName("http://wa.reachlocalads.com/");
        }

        else if (e.value === "dlt-dnd") {
            setPanelName("http://message.reachlocalads.com/");
        }

        else if (e.value === "numeric-non-dnd") {
            setPanelName("http://message.reachlocalads.com/");
        }

        else if (e.value === "sim-sms") {
            setPanelName("http://message.reachlocalads.com/");
        }
        else if (e.value === "voice") {
            setPanelName("http://voice-dnd.reachlocalads.com/");
        }
        else if (e.value === "dlt-normal") {
            setPanelName("http://message.reachlocalads.com/login.php");
        }

        else
            setPanelName("NA");
        console.log("setPanelName", e);
        console.log("panelname", panelName);

        props.handleChange(e);


    }




    return (
        <React.Fragment>
            <div className="senderId-modal--wrapper pad">

                {/*
                    !props.isCredentialsEdit && !props.isCredentialsAdd &&
                    <div className="flex flex-wrap businessView">
                        <div className="col-6 margin-right--half">
                            <div className="content">
                                <div className="header">ID</div>
                                <div className="meta">{props.infoEmail}</div>
                            </div>
                        </div>
                        <div className="col-6 margin-right--half">
                            <div className="content">
                                <div className="header">Password</div>
                                <div className="meta">{props.infoPassword}</div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="content">
                                <div className="header">Service Name</div>
                                <div className="meta">{props.infoType}</div>
                            </div>
                        </div>
                        <div className="col-15 margin-top">
                            <div className="content">
                                <div className="header">Panel URL</div>
                                <div className="meta">{props.infoPanel}</div>
                            </div>
                        </div>

                    </div>
    */}

                {
                    props.isCredentialsEdit &&
                    <React.Fragment>
                        <div className="align-space-between col-20 flex flex--wrap flex-direction--row">
                            <div className="col-6">
                                <label className="label col-5">Email</label>
                                <input type="text"
                                    className="form-control col-20"
                                    name="email"
                                    defaultValue={props.info.email}
                                    //value={props.info.email}
                                    onChange={props.bChangeHandler}
                                    placeholder="Email" />
                            </div>
                            <div className="col-6">
                                <label className="label col-5">Username</label>
                                <input type="text"
                                    className="form-control col-20"
                                    name="username"
                                    defaultValue={props.info.username}
                                    //value={props.info.username}
                                    onChange={props.bChangeHandler}
                                    placeholder="Username" />
                            </div>
                            <div className="col-6">
                                <label className="label col-5">Mobile No.</label>
                                <input type="text"
                                    className="form-control col-20"
                                    name="mobile"
                                    defaultValue={props.info.mobile}
                                    //value={props.info.mobile}
                                    onChange={props.bChangeHandler}
                                    placeholder="Mobile No." />
                            </div>
                        </div>
                        <div className="align-space-between col-20 flex flex--wrap flex-direction--row margin-top">
                            <div className="col-6">
                                <label className="label col-5">Password</label>
                                <input type="text"
                                    className="form-control col-20"
                                    name="password"
                                    defaultValue={props.info.password}
                                    //value={props.info.password}
                                    onChange={props.bChangeHandler}
                                    placeholder="Password" />
                            </div>
                            <div className="col-6">
                                <label className="label col-5">Service Name</label>
                                <Select


                                    name="type"
                                    defaultValue={serviceTypes[1]}
                                    value={serviceTypes.value}
                                    onChange={e => { handleSelectChange(e) }}
                                    options={serviceTypes}

                                />
                            </div>
                        </div>

                        <div className="align-space-between col-20 flex flex--wrap flex-direction--row margin-top">
                            <div className="col-14">
                                <label className="label col-5">Panel URL</label>
                                <div className="" >{panelName}</div>
                            </div>
                        </div>


                    </React.Fragment>
                }

                {
                    props.isCredentialsAdd && !props.isCredentialsEdit &&
                    <React.Fragment>
                        <div className="align-space-between col-20 flex flex--wrap flex-direction--row">
                            <div className="col-6">
                                <label className="label col-5">Email</label>
                                <input type="text"
                                    className="form-control col-20"
                                    name="email"
                                    value={props.emailInfo}
                                    onChange={props.bChangeHandler}
                                    placeholder="Email" />
                            </div>
                            <div className="col-6">
                                <label className="label col-5">Username</label>
                                <input type="text"
                                    className="form-control col-20"
                                    name="username"
                                    value={props.usernameInfo}
                                    onChange={props.bChangeHandler}
                                    placeholder="Username" />
                            </div>
                            <div className="col-6">
                                <label className="label col-5">Mobile No.</label>
                                <input type="text"
                                    className="form-control col-20"
                                    name="mobile"
                                    value={props.mobileInfo}
                                    onChange={props.bChangeHandler}
                                    placeholder="Mobile No." />
                            </div>
                        </div>
                        <div className="align-space-between col-20 flex flex--wrap flex-direction--row margin-top">
                            <div className="col-6">
                                <label className="label col-5">Password</label>
                                <input type="text"
                                    className="form-control col-20"
                                    name="password"
                                    value={props.passwordInfo}
                                    onChange={props.bChangeHandler}
                                    placeholder="Password" />
                            </div>

                            <div className="col-6">
                                <label className="label col-5">Service Name</label>
                                <Select


                                    name="type"
                                    value={props.typeInfo}
                                    onChange={e => { handleSelectChange(e) }}
                                    options={serviceTypesAdd}
                                />
                            </div>
                        </div>

                        <div className="align-space-between col-20 flex flex--wrap flex-direction--row margin-top">
                            <div className="col-14">
                                <label className="label col-5">Panel URL</label>
                                <div className="">{panelName}</div>

                            </div>
                        </div>
                    </React.Fragment>
                }

            </div>

            <div className="dialog-footer pad">
                {
                    props.isCredentialsAdd &&
                    <div>
                        <button className="ui button" onClick={props.closePopup}>
                            BACK
                        </button>
                        <button className="anchor-btn margin-left--half dialog--cta pointer" onClick={saveDetails}>
                            SAVE DETAILS
                        </button>
                    </div>
                }
                {
                    props.isCredentialsEdit &&
                    <div>
                        <button className="ui button" onClick={props.closePopup}>
                            BACK
                        </button>
                        <button className="ui green button" onClick={props.editCredentialsDetails}>
                            SAVE CHANGES
                        </button>
                    </div>
                }
                {
                    props.confirmationLoader &&
                    <div>
                        <CircularLoader stroke={"#c82506"} size={"36"} buttonSize={"50px"}></CircularLoader>
                    </div>
                }
            </div>
        </React.Fragment>
    );
}