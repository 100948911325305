import React from 'react';
import utils from '../../Services/utility-service';

export default function ViewCampaignWhatsAPP (props){
    return(
        <section className={utils.isMobile? "flex-direction--col flex" : "flex"}>
         
            <div className={utils.isMobile ? "col-20 card-custom" : "col-10 card-custom"}>
                <div className="pad text--bold text--darker" style={{fontSize:'16px'}}>
                    Campaign Content
                </div>
                {/* <div style={utils.isMobile ? {background:'#eeeeee'}:{background:'#eeeeee',minHeight:'180px',maxHeight:'180px',overflow:'auto'}}> */}
                <div style={utils.isMobile ? {background:'#eeeeee'}:{background:'#eeeeee',minHeight:'min-content',overflow:'auto'}}>
                    <div className="bdr-btm--wht pad--half">
                        <span className="text--bold text--darker">Campaign Message:&nbsp;</span>
                        <div style={{whiteSpace:"pre-wrap", paddingTop:"1%"}}>{props.detailCampaign.content.content ? props.detailCampaign.content.content : '--'}</div>
                    </div>
                    <div className="bdr-btm--wht pad--half">
                    {/* <span className="text--bold text--darker">Content Media Type:&nbsp;</span><span>{this.state.detailCampaign && this.state.detailCampaign.content && this.state.detailCampaign.content.contentMediaType ? this.state.detailCampaign.content.contentMediaType :"--" }</span> */}
                        <span className="text--bold text--darker">Content Media URL:&nbsp;</span><span>{props.detailCampaign && props.detailCampaign.content && props.detailCampaign.content.contentMediaUrl ? props.detailCampaign.content.contentMediaUrl :"--" }</span>
                    </div>
                    <div className="bdr-btm--wht pad--half">
                        <span className="text--bold text--darker">Content Media Type:&nbsp;</span><span>{props.detailCampaign && props.detailCampaign.content && props.detailCampaign.content.contentMediaType ? props.detailCampaign.content.contentMediaType :"--" }</span>
                    </div>
                    <div className="bdr-btm--wht pad--half">
                        <span className="text--bold text--darker">WhatsApp DP:&nbsp;</span><span>{props.detailCampaign && props.detailCampaign.content && props.detailCampaign.content.dpImageUrl ? props.detailCampaign.content && props.detailCampaign.content.dpImageUrl : "--"}</span>
                    </div>               
                </div>
            </div>
            <article className={utils.isMobile ? "col-20" : "col-10 margin-left"}>
                 <div className="card-custom">
                    <div className="pad text--bold text--darker" style={{fontSize:'16px'}}>
                        Filters
                    </div>
                    <div style={{background:'#eeeeee',minHeight:'180px',maxHeight:'180px',overflowY:'auto'}}>
                        {
                            props.detailCampaign.segments && props.detailCampaign.segments.length > 0 &&
                            props.detailCampaign.segments.map((item,index) => {
                                return(
                                    <div key={index} className="bdr-btm--wht pad--half">
                                        <span className="text--bold text--darker">{
                                            item.icon &&
                                            <span className="margin-right--quar">
                                                <i aria-hidden="true" className={`${item.icon} icon`}></i>
                                            </span>
                                        }
                                        {item.name}:&nbsp;</span>
                                        {   item.value && 
                                            <span>{item.value}</span>
                                        }
                                        {
                                            item.values && item.values.length > 0 &&
                                            <span>{item.values.toLocaleString()}</span>
                                        }
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            
         
            <div className="card-custom" 
            // style={utils.isMobile? null: {marginLeft:"50.8%"}}
            >
            <div className="pad" style={{fontSize:'16px'}}>
                <span className="text--bold text--darker">Short Url</span>
            </div>
            <div style={{background:'#eeeeee'}}>
                <div className="bdr-btm--wht pad--half">
                    <span className="text--bold text--darker">Short Url:&nbsp;</span><span>{props.detailCampaign.shortUrl ? props.detailCampaign.shortUrl:"--"}</span>
                </div>
                <div className="bdr-btm--wht pad--half">
                    <span className="text--bold text--darker">Campaign Description:&nbsp;</span><span>{props.detailCampaign.campaignDesc ? props.detailCampaign.campaignDesc : "--"}</span>
                </div>
            </div>
        </div>
        </article>
        </section>
    )
}