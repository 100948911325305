import React, { Component } from 'react';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import SvgIcon from '../../Components/Svg-icon/Svg-icon';
import Popup from '../../Components/Popup/Popup';
import utils from '../../Services/utility-service';
import { toHaveFocus } from '@testing-library/jest-dom';
import { addEditEmailTemplates } from '../../Services/lead-service'

const initialState = {
    templateDetails: ["-"],
    addNew: true,
}

export default class CreateEmailTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = initialState;
        this.submitData = this.submitData.bind(this);
        this.closeAction = this.closeAction.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.changeHandler = this.changeHandler.bind(this);


    }

    submitData() {
        console.log("inside add data");
        console.log("Saved success");

        const body = {
            //emailTemplateId: this.state.templateDetails.id,
            subject: this.state.templateDetails.subjectTemplate,
            templateBody: this.state.templateDetails.bodyTemplate,
            emailType: this.state.templateDetails.type,
            name: this.state.templateDetails.name,
            fromEmail: "hello@noreply.expletuslabs.com",
            fromName: "Reach Local Ads",
            noreplyEmail: "hello@noreply.expletuslabs.com",
            emailChannelId: 1,
        }

        /*addEditEmailTemplates(body)
            .then(response => response.json())
            .then(data => {
                if (data.success) {

                    console.log("Inside add data to backend", this.state.templateDetails.name, this.state.templateDetails.bodyTemplate,
                    this.state.templateDetails.subjectTemplate, this.state.templateDetails.to, this.state.templateDetails.cc, 
                    this.state.templateDetails.bcc, this.state.templateDetails.type);
                    ToastsStore.success("Details Saved Successfully !");
                    console.log("data",data);


                } else {
                    ToastsStore.error(data.message);
                }
            }).catch(error => {
                console.log(error);
                ToastsStore.error("Something went wrong, Please Try Again Later ");
            })*/

        console.log("add email template", body);

    }

    closeAction() {
        this.setState({
            addNew: false,
        })
    }

    changeHandler(event) {
        let name = event.target.name;
        let val = event.target.value;
        let temp = this.state.templateDetails;
        temp[name] = val;

        this.setState({
            templateDetails: temp,
        })
        console.log("bhandler", temp);
        console.log("State-hndlr", this.state.templateDetails);
    }

    render() {
        return (
            <React.Fragment>
                

                    <section>
                        <div className="senderId-modal--wrapper">
                            <div className="col-9">
                                <div className="label">Name</div>
                                <input type="text"
                                    className="form-control"
                                    name="name"
                                    value={this.state.templateDetails.name}
                                    onChange={this.changeHandler}
                                />
                            </div>

                            <div className="col-9">
                                <div className="label">Body Template</div>
                                <input type="text"
                                    className="form-control"
                                    name="bodyTemplate"
                                    value={this.state.templateDetails.bodyTemplate}
                                    onChange={this.changeHandler}
                                />
                            </div>

                            <div className="col-9">
                                <div className="label">Subject Template</div>
                                <input type="text"
                                    className="form-control"
                                    name="subjectTemplate"
                                    value={this.state.subjectTemplate}
                                    onChange={this.changeHandler}
                                />
                            </div>

                            <div className="col-9">
                                <div className="label">To</div>
                                <input type="text"
                                    className="form-control"
                                    name="to"
                                    value={this.state.templateDetails.to}
                                    onChange={this.changeHandler}
                                />
                            </div>

                            <div className="col-9">
                                <div className="label">Cc</div>
                                <input type="text"
                                    className="form-control"
                                    name="cc"
                                    value={this.state.templateDetails.cc}
                                    onChange={this.changeHandler}
                                />
                            </div>

                            <div className="col-9">
                                <div className="label">Bcc</div>
                                <input type="text"
                                    className="form-control"
                                    name="bcc"
                                    value={this.state.templateDetails.bcc}
                                    onChange={this.changeHandler}
                                />
                            </div>

                            <div className="col-9">
                                <div className="label">Type</div>
                                <input type="text"
                                    className="form-control"
                                    name="type"
                                    value={this.state.templateDetails.type}
                                    onChange={this.changeHandler}
                                />
                            </div>

                            <div className="dialog-footer pad">
                                <div>
                                    <button className="btn btn-fill dialog--cta pointer" onClick={() => this.props.closePopup()}>
                                        Back
                                    </button>
                                    <button onClick={() => this.submitData()} className="btn btn-fill btn-success margin-left--half dialog--cta pointer">Save Changes</button>
                                </div>
                            </div>


                        </div>
                    </section>
                


            </React.Fragment>
        );
    }

}