import React from 'react';
import Form from '../../LandingPage/LandingPage';
import utils from '../../../Services/utility-service';


export default function CoverPage(props){

    const str = props.note.split("   ");
    return(
        <div style={{backgroundColor :`${props.backgroundColor ? props.backgroundColor : null}`,position:"relative"}} className="width-OnefourFourZero height-FiveSixZero">
            <img src={props.src} alt="CoverPage" width={props.width} height={props.height} style={{marginRight:`${props.marginRight ? props.marginRight : null}`}}/>
            <span style={utils.isMobile ? {position:"absolute",top:"17%",right:"0%",fontSize:"1em",fontFamily:"ui-monospace",color:"rgb(19, 64, 147)"}: {position:"absolute",top:"2%",right:"15%",fontSize:"3em",fontFamily:"ui-monospace",color:"rgb(19, 64, 147)"}}>{ props.note ? 
            <React.Fragment>
                <div><span>{str[0].split("  ")[0]}</span><span style={{fontWeight:"900"}}>{" "+str[0].split("  ")[1]}</span></div>
                <div><span>{str[1].split("  ")[0]}</span><span style={{fontWeight:"900"}}>{" "+str[1].split("  ")[1]}</span></div>
            </React.Fragment> : null }</span>
            {/* <Form style={{display: "block",borderRadius:"20px",zIndex:"52",width:"100%",height:"100%",position:"absolute"}}/> */}
            <Form/>
        </div>
    )
}