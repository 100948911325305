import React from 'react';
import Email from '../../Constants/img/email.png';
import Phone from '../../Constants/img/phone.png';
import WhatsApp from '../../Constants/img/whatsapp-icon.png';
import utils from '../../Services/utility-service';

export default function ContactUS (props){
    return(
        <main style={utils.isMobile ? {paddingTop:"20px"}:{padding:"40px"}}>
            {
                utils.isMobile ?  (<h4 className="text--center">{props.title.split("  ")[0]}<br/> {props.title.split("  ")[1]}</h4>) :   (<h3 className="text--center">{props.title}</h3>)
            }

            <section className={`${utils.isMobile ? 'flex flex-direction--col vert-space-between margin-top-five':'flex align-space-around margin-top-five' }`}>
                <div className={utils.isMobile ? "margin-btm--half" : null}>
                    <a target="_blank" rel="noopener noreferrer" href={props.url}>
                        {/* <i class="whatsapp icon" style={{fontSize:"2.8em"}}></i> */}
                        {/* <img alt="Icon" style={{width:"70%"}} src="https://img.icons8.com/color/48/000000/whatsapp--v4.png" /> */}
                        <div className="text--center">
                            <img alt="Email Icon" style={utils.isMobile ? {width:'15%'} : {width:"75%"} } src={WhatsApp} />
                            <p>Chat Now</p>
                        </div>
                    </a>
                </div>
                <div className={utils.isMobile ? "margin-btm--half" : null}>
                    <a target="_blank" rel="noopener noreferrer" href={`tel:${props.mob}`}>
                        <div className="text--center">
                            <img alt="phone Icon" style={utils.isMobile ? {width:'15%'} : {width:"43%"}} src={Phone} />
                            {/* <SvgIcon icon={"call"} classes={'pointer svg--xxl'} style={{height:"40px",width:"40px"}}></SvgIcon>   */}
                            {/* <p>Call Now</p> */}
                            <p>{props.mob}</p>
                        </div>
                    </a>
                </div>
                <div className={utils.isMobile ? "margin-btm--half" : null}>
                    <a target="_blank" rel="noopener noreferrer" href={`mailto:operations@expletusmarketing.com`}>
                        {/* <i class="envelope open icon" style={{fontSize:"2.5em"}}></i>  */}
                        <div className="text--center">
                            <img alt="Email Icon" style={utils.isMobile ? {width:'15%'} : {width:"20%"}} src={Email} />
                            {/* <figcaption>Email Now</figcaption> */}
                            <figcaption>operations@expletusmarketing.com</figcaption>
                        </div>
                    </a>
                </div>
            </section>
        </main>
    )
}