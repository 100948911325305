
const serverOrigin = window.location.origin;
async function dashboardAPI(body){
  const response =
    await fetch( serverOrigin+"/dashboard/get/elements",
      { 
        method: 'post',
        headers: {'Content-Type':'application/json','Accept': 'application/json'},
        body: JSON.stringify(body)
      }
    )
  return await response; 
}

export {dashboardAPI};