import React, { useEffect, useState } from 'react';
import Popup from '../Popup/Popup';
import CircularLoader from '../circular-loader/circular-loader';
import { getCredentials, getAllCredentials } from '../../Services/campaign-service';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

export default function CampaignSIM(props) {

    const [loader, setLoader] = useState(false);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [username, setUsername] = useState(null);
    const [details, setDetails] = useState(false);
    const [noCredentials, setNoCredentials] = useState(false);

    useEffect(() => {
        setLoader(true);
        let body = {
            "type": "sim-sms",
        }
        getAllCredentials(body)
            .then(r => r.json())
            .then(data => {
                setLoader(false);
                if (data.success) {
                    setEmail(data.email);
                    setPassword(data.password);
                    setUsername(data.username);
                    // ToastsStore.success("Fetched Credentials Successfully. Please Click Show Credential");
                }
                else {
                    //ToastsStore.error(data.message);
                    //setNoCredentials(true);
                }
            })
            .catch(e => {
                setLoader(false);
                ToastsStore.error("Something went wrong. Difficult to show credentials");
            })
    }, [])

    return (
        <React.Fragment>
            {
                details && password !== null &&
                <Popup title="Credentials" togglePopup={() => setDetails(false)}>
                    <article className="senderId-modal--wrapper">
                        <div >
                            <span className="label" style={{ fontSize: "large" }}>Email:</span><span style={{ fontSize: "large" }}>{email}</span>
                        </div>

                        <div className="col-20 margin-top">
                            <span className="label" style={{ fontSize: "large" }}>Username:</span><span style={{ fontSize: "large" }}>{username}</span>
                        </div>

                        <div className="col-20 margin-top">
                            <span className="label" style={{ fontSize: "large" }}>Password:</span><span style={{ fontSize: "large" }}>{password}</span>
                        </div>

                        
                    </article>
                    <div className="dialog-footer pad">
                        <button
                            className="btn btn-fill dialog--cta pointer"
                            onClick={() => setDetails(false)}>
                            Close
                        </button>
                    </div>
                    {
                        loader &&
                        <div className="global-loader col-1">
                            <CircularLoader stroke={"#0c73a5"} size={"36"} buttonSize={"50px"}></CircularLoader>
                        </div>
                    }
                </Popup>
            }
            {
                (details && password == null) &&
                <Popup title="Credentials" togglePopup={() => setDetails(false)}>
                    <article className="senderId-modal--wrapper">
                        <div >
                            <span className="label" style={{ fontSize: "large" }}>Please Buy SIM SMS Package</span><span style={{ fontSize: "large" }}></span>
                        </div>

                    </article>
                    <div className="dialog-footer pad">
                        <button
                            className="btn btn-fill dialog--cta pointer"
                            onClick={() => setDetails(false)}>
                            Close
                        </button>
                    </div>

                </Popup>

            }
            <div className="flex justify-flex-end algn-vert-center">
                <a href="http://message.reachlocalads.com/" target="_blank" rel="noopener noreferrer"><b>Go to SIM SMS Panel</b></a>
                <button className="btn btn-fill btn-success" style={{ width: "15%", marginLeft: "1%" }} onClick={() => setDetails(true)}>Show Credentials</button>
            </div>

            <iframe title="SIMSMS" src="http://message.reachlocalads.com/" width="100%" height="400"></iframe>

            <ToastsContainer position={ToastsContainerPosition.TOP_RIGHT} lightBackground store={ToastsStore} />

        </React.Fragment>
    )
}
