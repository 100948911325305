import React from 'react';
import {Link} from 'react-router-dom';
import utils from '../../Services/utility-service';

export default function CardHeading(props){
  
    
    return(
        <section className={utils.isMobile ? null:"margin-auto col-15"}>
            <div className="margin-left padding-top-six">
                <h3 style={utils.isMobile?{fontSize: "1.2rem"}:{fontSize: "1.4rem"}}>{props.cardHeading && props.cardHeading.boxContent && props.cardHeading.boxContent.title}</h3>
                <p style={utils.isMobile?{fontSize: "1.0rem"}:{fontSize: "1.2rem"}}>{props.cardHeading && props.cardHeading.boxContent && props.cardHeading.boxContent.text}</p>
            </div>
            <div style={{fontSize: "1.2rem"}} className="text--center padding-top-three">{props.cardHeading && props.cardHeading.elementsHeader}</div>
            <div className={utils.isMobile ? "flex justify-content--center flex-direction--col" : "flex justify-content--center"}>
            {
                props.cardHeading && props.cardHeading.elements && props.cardHeading.elements.map((item,index)=>{
                    return(
                        <div key={index} className={utils.isMobile ? "card-border mar--half pad--half margin-left--auto margin-right--auto" : "card-border pad mar"} style={{position:"relative"}}>
                               {
                                   props.cardHeading && props.cardHeading.elements && props.cardHeading.elements.length>1 &&
                                    <div style={utils.isMobile ? {fontSize: "1.14rem"}:{fontSize: "1.4rem"}}><b>{index+1}</b></div>
                               }
                            <div style={{fontSize: "1.1rem"}}><b>{item.title}</b></div>
                            {/* <div style={{fontSize: "1.4rem"}}><b>{item.title}</b></div> */}
                            <p style={utils.isMobile ? {fontSize: "1.0rem"}:{fontSize: "1.2rem"}} className="padding-top-three">{item.text}</p>
                            <Link to={{pathname:item.cta && item.cta.redUrl.split("?")[0],search:item.cta && item.cta.redUrl.split("?")[1]}}>
                                <div className="justify-content--center cta-btn text--center"  style={{cursor:"pointer",position:"absolute",bottom:"25px",left:"7px",right:"7px"}}
                                // onClick={()=>handleClick(item.code)}
                                >{item.cta && item.cta.buttonText}</div>
                            </Link>
                        </div>
                    )
                })
            }
            </div>

        </section>
    )
}