import React,{useState} from 'react';
import './GenericWhatsApp.css';
//import Popup from '../../Popup/Popup';
import utils from '../../../Services/utility-service';

export default function MediumOBDFile(props){
    const [result, setResult] = useState(null);
    const [type,setType] = useState(null);
    const [name, setName] = useState(null);
    const [error,setError]=useState(null);
    //const [file,selectFile] = useState(null);

    function selectAudio(event){
        if(event.target.files[0]){
            setResult(event.target.files[0]); // To clear last saved cropped image
            setName(event.target.files[0].name);
            let t=event.target.files[0].type.split("/");
            setType(t[0]);      
        }
        else{
            return;
        } 
        console.log(event.target.files);
        console.log("result",result);  
    }

    function finalUpload(){
        console.log("button clicked");
        if(parseInt(result.size) <= parseInt(10000000)){
            props.handleAudioUpload(result,type,name)
            setError(null);
        }
        else{
            setError(`Uploaded File size is ${(result.size/1000000).toFixed(2)} MB. Max size of the uploaded file should not exceed 10MB`);
        }
        
    }

    return(
        <div className="padding-all-12">
        <div className="flex">
        <div className="label">Upload Audio File</div>
        <div className="form-control" style={{width:"90%"}}>
            {utils.isMobile ? null :<i class="upload icon"></i>}
                <input type="file" multiple={false} accept="audio/*"
                    className="audio-file-input upload icon" onChange={(event) =>selectAudio(event)}>
                </input>  
                {/* <i className="text-small">(Max limit 10MB)</i> */}
                {/* { error &&  
                     <span className="form-error">{error}</span>
                } */}
                {/* <div className="text--right"><button className="btn btn-fill btn-success" onClick={finalUpload}>Upload</button>
                </div> */}
        </div>
        <i className="text-small">(Max limit 10MB)</i>
        { error &&  
                     <span className="form-error">{error}</span>
        }
        </div> 
        <div>
            <div className="col-9 margin-left--auto padding-all-12"><button className="btn btn-fill btn-success" onClick={finalUpload}>Upload</button>
                </div>
        </div>
        </div>  
    )
}

// class MediumOBDFile extends Component{
//     constructor(props){
//         super(props);
//         this.state={
//             file:null,
//             error:null,
//             result:null,
//             name:null,
//             type:null
//         }
//     }

//     selectAudio = event =>{
//         if(event.target.files[0]){
//             let t=event.target.files[0].type.split("/");
//             this.setState({
//                 // file: URL.createObjectURL(event.target.files[0]),
//                 // error:null,
//                 // result:null,
//                 name:event.target.files[0].name,
//                 type:t[0]

//             })
//             console.log(event.target.files)
//             console.log("file ",this.state.file);
//         }
//         else{
//             return;
//         }
//     }

//     finalUpload=()=>{
//            this.props.handleAudioUpload(this.state.file,this.state.type,this.state.name);
//     }

//     render(){
//         return(
//             <div className="padding-all-12 col-9">
//             <div className="label">Upload Audio File</div>
//             <div className="form-control" style={{width:"90%"}}>
//                 {utils.isMobile ? null :<i class="upload icon"></i>}
//                 <input type="file" multiple={false} accept="audio/*"
//                     className="custom-file-input upload icon" onChange={(event) =>this.selectAudio(event)}>
//                 </input> 
//                 <div className="text--center"><button className="btn btn-fill btn-success"
//                       onClick={this.finalUpload}>Upload</button></div>
                
//             </div>
//             </div>
//         )
//     }
// }
// export default MediumOBDFile;

// export default function MediumOBDFile(props){
//     const [withoutUpld, setWithoutUpld] = useState(null);
//     const [error, setError] = useState(null);
//     const [file,selectFile] = useState(null);
//     // const [image,setImage] = useState(null);
//     const [display,setDisplay] = useState(null);
//     const [result, setResult] = useState(null);
//     const [type,setType] = useState(null);
//     const [name, setName] = useState(null);

//     function selectImage(event){
//         if(event.target.files[0]){
//             selectFile(URL.createObjectURL(event.target.files[0]));
//             setWithoutUpld(event.target.files[0]);
//             setError(null);
//             props.toggleLoader();
//             setResult(null); // To clear last saved cropped image
//             setName(event.target.files[0].name);
//             let t=event.target.files[0].type.split("/");
//             setType(t[0]);          
//         }
//         else{
//             return;
//         }    
//     }

//     // function uploadImage(){
//     //     if(parseInt(image.naturalWidth) <= 192 && parseInt(image.naturalHeight) <= 192){ 
//     //         setError(null);
//     //         props.toggleLoader();
//     //         props.handleUpload(withoutUpld,type,"DP");
//     //         URL.revokeObjectURL(file);
//     //         selectFile(null);    
           
//     //     }
//     //     else{
//     //      setError("Max size of image should be 192 X 192 pixels. Kindly crop image before uploading");
//     //      return;
//     //     }
//     //  }

//      function finalUpload(){
        
//         if(parseInt(display.width) <= 192 && parseInt(display.height) <= 192){
//             props.toggleLoader();
//             setError(null);
//             URL.revokeObjectURL(file);         
//             props.handleImageUpload(result,type,name,"DP");
//             selectFile(null);
//         }
//         else{
//             setError("Max size of image should be 192 X 192 pixels. Kindly crop image before uploading");
//             return;
//         }
//     }
//     return (
//         <div className="padding-all-12 col-9">
//         <div className="label">Upload WhatsApp DP</div>
//         <div className="form-control" style={{width:"90%"}}>
//      {utils.isMobile ? null :<i class="upload icon"></i>}
//      <input type="file" multiple={false} accept="image/*" 
//     //  onClick={()=>setLoader(true)}
//       className="custom-file-input upload icon" onChange={(event) =>selectImage(event)}></input>
//       </div>                      
//     <i className="text-small">(Max limit 192x192 pixels)</i>
//     {/* <div className="text--center"><button className="btn btn-fill btn-success"
//                       onClick={finalUpload}>Upload</button></div> */}
//     </div>
//     )
// }