import React, { Component } from "react";
import Moment from 'react-moment';
import { ItemMeta } from 'semantic-ui-react';
import utils from '../../Services/utility-service';
import CircularLoader from '../circular-loader/circular-loader';
import { saveInvoiceStatus } from '../../Services/shortUrl-service';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';


const initialState = {
    agencyPaymentDate: null,
    agencyPaymentReferenceNumber: null,
    invoiceNumber: null,
    invoiceLink: null,
    invoiceStatus: null,
    info: null,
    details: false,
}

export default class RevenueInvoiceStatus extends Component {

    constructor(props) {
        super(props);
        this.state = initialState;


    }

    saveDetails() {
        console.log("item", this.state.info);
        console.log("Saved success");

        const body = {
            invoiceId: this.state.info.invoiceId,
            agencyPaymentDate: "12/12/2021",
            agencyPaymentReferenceNumber: null,
            invoiceNumber: this.state.info.agencyInvoiceNumber,
            invoiceLink: this.state.info.agencyInvoiceLink,
            agencyBusinessUid: this.state.info.agency.uid,
            clientBusinessUid: this.state.info.client.uid,
            invoiceStatus: "PAID",
        }
        saveInvoiceStatus(body)
            .then(response => response.json())
            .then(data => {
                if (data.success) {

                    console.log("Inside add data to backend", this.state.info.paymentDate, this.state.info.paymentRefernceNumber,
                        this.state.info.agencyInvoiceNumber, this.state.info.agencyInvoiceLink, this.state.info.agency.name, 
                        this.state.info.client.name);
                    ToastsStore.success("Details Saved Successfully !");


                } else {
                    ToastsStore.error(data.message);
                }
            }).catch(error => {
                console.log(error);
                ToastsStore.error("Something went wrong, Please Try Again Later ");
            })

        console.log("add new invoice status", body);



    }

    bChangeHandler(event) {
        let name = event.target.name;
        let val = event.target.value;
        let temp = this.state.info;
        temp[name] = val;

        this.setState({
            info: temp,
        });
        console.log("bhandler", temp);
        console.log("State-hndlr", this.state.info);
    }

    handleSelectChange(event) {

        const name = event.target.name;
        const value = event.target.value;
        console.log("name", name, "value", value);

    }

    closePopup() {
        //setDetails(false);
        //setInfo(itemformControl);

        this.setState({
            details: false,
        })
    }



    render() {

        return (

            <React.Fragment>
                {!this.state.details &&
                    <div>
                        <article className="">
                            <div className="align-space-between col-20 flex flex--wrap flex-direction--row margin-top">
                                <div className="col-6">
                                    <label className="label col-5">Total Amount</label>
                                    <div className="" >{this.props.info.amount}</div>

                                </div>
                                <div className="col-6">
                                    <label className="label col-5">Transaction Amount</label>
                                    <div className="" >{this.props.info.txnAmount}</div>

                                </div>
                                {utils.isSuAdmin &&
                                    <div className="col-6">
                                        <label className="label col-5">Agency</label>
                                        <div className="" >{this.props.info.agency.name}</div>

                                    </div>
                                }
                                <div className="col-6">
                                    <label className="label col-5">Client</label>
                                    <div className="" >{this.props.info.client.name}</div>

                                </div>
                                <div className="col-8">
                                    <label className="label col-5">Client Email</label>
                                    <div className="" >{this.props.info.client.email}</div>

                                </div>
                            </div>
                            <div className="align-space-between col-20 flex flex--wrap flex-direction--row margin-top">
                                <div className="col-6">
                                    <label className="label col-5">Payment Date</label>
                                    <div className="" ><Moment format="YYYY/MM/DD">{this.props.info.paymentDate}</Moment></div>

                                </div>
                                <div className="col-6">
                                    <label className="label col-5">Remarks</label>

                                    <input type="text"
                                        className="form-control col-20"
                                        name="remarks"
                                        defaultValue={this.props.info.remarks}
                                        onChange={this.bChangeHandler()}
                                        placeholder="Remarks" />

                                </div>
                            </div>
                            <div className="align-space-between col-20 flex flex--wrap flex-direction--row margin-top">
                                <div className="col-6">
                                    <label className="label col-5">Status</label>
                                    <div>
                                        <select
                                            name="status"
                                            value={this.props.info.invoiceStatus}
                                            onChange={this.handleSelectChange()}>
                                            <option value="UNPAID" defaultValue hidden>Unpaid</option>
                                            <option value="UNPAID">Unpaid</option>
                                            <option value="PAID">Paid</option>
                                            <option value="CANCELLED">Cancelled</option>
                                        </select>
                                    </div>

                                </div>
                                <div className="col-6">
                                    <label className="label col-5">Agency Payment Date</label>
                                    <div>
                                        <input type="text"
                                            className="form-control col-20"
                                            name="agencyPaymentDate"
                                            defaultValue={this.props.info.agencyPaymentDate}
                                            //value={item.agencyPaymentDate}
                                            onChange={this.bChangeHandler()}
                                            placeholder="Agency Payment Date" />
                                    </div>
                                </div>

                                <div className="col-6">
                                    <label className="label col-5">Agency Payment Reference Number</label>
                                    <div>
                                        <input type="text"
                                            className="form-control col-20"
                                            name="paymentReferenceNumber"
                                            defaultValue={this.props.info.paymentReferenceNumber}
                                            //value={item.paymentRefernceNumber}
                                            onChange={this.bChangeHandler()}
                                            placeholder="Agency Payment Reference Number" />
                                    </div>
                                </div>
                            </div>
                            <div className="align-space-between col-20 flex flex--wrap flex-direction--row margin-top">
                                <div className="col-6">
                                    <label className="label col-5">Agency Invoice Number</label>
                                    <div>
                                        <input type="text"
                                            className="form-control col-20"
                                            name="agencyInvoiceNumber"
                                            defaultValue={this.props.info.agencyInvoiceNumber}
                                            //value={item.agencyInvoiceNumber}
                                            onChange={this.bChangeHandler()}
                                            placeholder="Agency Invoice Number" />
                                    </div>
                                </div>

                                <div className="col-6">
                                    <label className="label col-5">Agency Invoice Link</label>
                                    <div>
                                        <input type="text"
                                            className="form-control col-20"
                                            name="agencyInvoiceLink"
                                            defaultValue={this.props.info.agencyInvoiceLink}
                                            //value={item.agencyInvoiceLink}
                                            onChange={this.bChangeHandler()}
                                            placeholder="Agency Invoice Link" />
                                    </div>
                                </div>
                            </div>


                        </article>
                        <div className="dialog-footer pad margin-top">

                            <button className="ui green button" onClick={() => this.saveDetails()}>
                                SAVE CHANGES
                            </button>

                            <button
                                className="btn btn-fill dialog--cta pointer"
                                onClick={this.closePopup()}>
                                Close
                            </button>

                        </div>

                    </div>

                }
            </React.Fragment >




        );
    }
}