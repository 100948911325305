const serverOrigin = window.location.origin;

async function getCallDetail(body){
   const response =
  //  await fetch( serverOrigin+"/lead/get/campaigns",
     await fetch( serverOrigin+"/call/get/calling/details",
     { 
         method: 'post',
         headers: {'Content-Type':'application/json','Accept': 'application/json'},
         body: JSON.stringify(body)
       }
     )
   return await response; 
 }

 async function getMissedCallDetail(body){
  const response =
 //  await fetch( serverOrigin+"/lead/get/campaigns",
    await fetch( serverOrigin+"/calling/misscall/get/history",
    { 
        method: 'post',
        headers: {'Content-Type':'application/json','Accept': 'application/json'},
        body: JSON.stringify(body)
      }
    )
  return await response; 
}
 
export {getCallDetail,getMissedCallDetail}