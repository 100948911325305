import React, { Component } from 'react';
import Popup from '../Popup/Popup';
import CircularLoader from '../circular-loader/circular-loader';
import { getCredentials } from '../../Services/campaign-service';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import utils from '../../Services/utility-service';
import { getAllCredentials } from '../../Services/campaign-service';
import { getAllTypeCredentials } from '../../Services/campaign-service';
import { addIdPasswordDetails, editIdPasswordDetails } from '../../Services/idpassword-service';
import IdPasswordDetails from '../../Components/IdPasswords/IdPasswordDetails';


const initialState = {

    viewCredentialsDetails: false,
    addCredentialsDetails: false,
    allCredentials: [{ email: "--", username: "--", password: "--", type: "--", panel: "--" }],
    index: null,
    storedCredentialsinfo: [{ email: "--", username: "--", password: "--", type: "--", panel: "--" }],
    credentialsEmail: null,
    credentialsUsername: null,
    credentialsPassword: null,
    credentialsType: null,
    credentialsPanel: null,
    isBusinessEdit: false,
    username: null,
    email: null,
    password: null,
    mobile:null,
    type: null,
    usernameSms: null,
    emailSms: null,
    passwordSms: null,
    mobileSms:null,
    typeSms: null,
    usernameVoice: null,
    emailVoice: null,
    passwordVoice: null,
    mobileVoice:null,
    typeVoice: null,
    usernameDnd: null,
    emailDnd: null,
    passwordDnd: null,
    mobileDnd:null,
    typeDnd: null,
    usernameNonDnd: null,
    emailNonDnd: null,
    passwordNonDnd: null,
    mobileNonDnd:null,
    typeNonDnd: null,
    credentialsIndex: null,
    storeCredentialindex: null,
    formControls: null,

}

export default class IdPassword extends Component {

    constructor(props) {
        super(props);
        this.state = initialState;
        this.editDetails = this.editDetails.bind(this);

    }

    componentDidMount() {
        this.getAllLoginCredentials();
        this.getSmsCredentials();
        this.getVoiceCredentials();
        this.getDltDndCredentials();
        this.getNonDndCredentials();
        this.pushalldata();



    }

    pushalldata() {
        /* let credentials = [
            { "email": this.state.email, "username": this.state.username, "password": this.state.password, "mobile":this.state.mobile, "type": "WhatsApp", "typeValue":"wapp", "panel": "http://wa.reachlocalads.com/" },
            { "email": this.state.emailSms, "username": this.state.usernameSms, "password": this.state.passwordSms, "mobile":this.state.mobileSms, "type": "SIM SMS", "typeValue":"sim-sms", "panel": "http://message.reachlocalads.com/" },
            { "email": this.state.emailVoice, "username": this.state.usernameVoice, "password": this.state.passwordVoice, "mobile":this.state.mobileVoice, "type": "Voice OBD", "typeValue":"voice", "panel": "http://voice-dnd.reachlocalads.com/" },
            { "email": this.state.emailDnd, "username": this.state.usernameDnd, "password": this.state.passwordDnd, "mobile":this.state.mobileDnd, "type": "DLT-DND", "typeValue":"dlt-dnd", "panel": "http://message.reachlocalads.com/" },
            { "email": this.state.emailNonDnd, "username": this.state.usernameNonDnd, "password": this.state.passwordNonDnd, "mobile":this.state.mobileNonDnd, "type": "NUMERIC NON DND", "typeValue":"numeric-non-dnd",  "panel": "http://message.reachlocalads.com/" },

        ]*/

         let credentials = [
            { "email": this.state.email, "username": this.state.username, "password": this.state.password, "mobile":this.state.mobile, "type": "WhatsApp", "typeValue":"wapp", "panel": "http://wa.reachlocalads.com/" },
            { "email": this.state.emailSms, "username": this.state.usernameSms, "password": this.state.passwordSms, "mobile":this.state.mobileSms, "type": "SIM SMS", "typeValue":"sim-sms", "panel": "http://message.reachlocalads.com/" },
            { "email": this.state.emailVoice, "username": this.state.usernameVoice, "password": this.state.passwordVoice, "mobile":this.state.mobileVoice, "type": "Voice OBD", "typeValue":"voice", "panel": "http://voice-dnd.reachlocalads.com/" },
            { "email": this.state.emailDnd, "username": this.state.usernameDnd, "password": this.state.passwordDnd, "mobile":this.state.mobileDnd, "type": "DLT-DND", "typeValue":"dlt-dnd", "panel": "http://message.reachlocalads.com/" },
            { "email": this.state.emailNonDnd, "username": this.state.usernameNonDnd, "password": this.state.passwordNonDnd, "mobile":this.state.mobileNonDnd, "type": "NUMERIC NON DND", "typeValue":"numeric-non-dnd",  "panel": "http://message.reachlocalads.com/" },

        ]



        //let credss = credentials;
        this.setState({
            allCredentials: credentials,
            storedCredentialsinfo: credentials,
        })


    }



    getAllLoginCredentials() {
        let body = {
            "type": "wapp",
            "businessUid": this.props.businessUid
        }
        getAllTypeCredentials(body)
            .then(r => r.json())
            .then(data => {
                //setLoader(false);
                if (data.success) {
                    this.setState({

                        email: data.email,
                        username: data.username,
                        password: data.password,
                        mobile:data.mobile,
                        type: data.type

                    });
                    // ToastsStore.success("Fetched Credentials Successfully. Please Click Show Credential");
                }
                else {
                    // ToastsStore.error(data.message);
                }
            })
            .catch(e => {
                //setLoader(false);
                ToastsStore.error("Something went wrong. Difficult to show credentials");
            })


        //this.pushalldata();

    }

    getSmsCredentials() {
        let bodySms = {
            "type": "sim-sms",
            "businessUid": this.props.businessUid
        }
        getAllTypeCredentials(bodySms)
            .then(r => r.json())
            .then(data => {
                //setLoader(false);
                if (data.success) {
                    this.setState({

                        emailSms: data.email,
                        usernameSms: data.username,
                        passwordSms: data.password,
                        mobileSms:data.mobile,
                        typeSms: data.type

                    });
                    // ToastsStore.success("Fetched Credentials Successfully. Please Click Show Credential");
                }
                else {
                    //ToastsStore.error(data.message);
                }
            })
            .catch(e => {
                //setLoader(false);
                ToastsStore.error("Something went wrong. Difficult to show credentials");
            })

        //let credential = { "email": this.state.emailSms, "username": this.state.usernameSms, "password": this.state.passwordSms, "type": "SIM SMS", "panel": "http://message.reachlocalads.com/" }
        //credentials.push(credential);
        //this.pushalldata();

    }

    getVoiceCredentials() {
        let bodyVoice = {
            "type": "voice",
            "businessUid": this.props.businessUid
        }
        getAllTypeCredentials(bodyVoice)
            .then(r => r.json())
            .then(data => {
                //setLoader(false);
                if (data.success) {
                    this.setState({

                        emailVoice: data.email,
                        usernameVoice: data.username,
                        passwordVoice: data.password,
                        mobileVoice:data.mobile,
                        typeVoice: data.type

                    });
                    // ToastsStore.success("Fetched Credentials Successfully. Please Click Show Credential");
                }
                else {
                    //ToastsStore.error(data.message);
                }
            })
            .catch(e => {
                //setLoader(false);
                ToastsStore.error("Something went wrong. Difficult to show credentials");
            })

        // let credentials = { "email": this.state.emailVoice, "username": this.state.usernameVoice, "password": this.state.passwordVoice, "type": "Voice OBD", "panel": "http://voice-dnd.reachlocalads.com/" }
        //credentials.push(credentials);
        //this.pushalldata();

    }


    getDltDndCredentials() {
        let bodyDnd = {
            "type": "dlt-dnd",
            "businessUid": this.props.businessUid
        }
        getAllTypeCredentials(bodyDnd)
            .then(r => r.json())
            .then(data => {
                //setLoader(false);
                if (data.success) {
                    this.setState({

                        emailDnd: data.email,
                        usernameDnd: data.username,
                        passwordDnd: data.password,
                        mobileDnd:data.mobile,
                        typeDnd: data.type

                    });
                    // ToastsStore.success("Fetched Credentials Successfully. Please Click Show Credential");
                }
                else {
                    //ToastsStore.error(data.message);
                }
            })
            .catch(e => {
                //setLoader(false);
                ToastsStore.error("Something went wrong. Difficult to show credentials");
            })

        //let credentials = { "email": this.state.emailDnd, "username": this.state.usernameDnd, "password": this.state.passwordDnd, "type": "DLT-DND", "panel": "http://message.reachlocalads.com/" }
        //credentials.push(credentials);
        //this.pushalldata();

    }

    getNonDndCredentials() {
        let bodyNonDnd = {
            "type": "numeric-non-dnd",
            "businessUid": this.props.businessUid
        }
        getAllTypeCredentials(bodyNonDnd)
            .then(r => r.json())
            .then(data => {
                //setLoader(false);
                if (data.success) {
                    this.setState({

                        emailNonDnd: data.email,
                        usernameNonDnd: data.username,
                        passwordNonDnd: data.password,
                        mobileNonDnd:data.mobile,
                        typeNonDnd: data.type

                    });
                    // ToastsStore.success("Fetched Credentials Successfully. Please Click Show Credential");
                }
                else {
                    //ToastsStore.error(data.message);
                }
            })
            .catch(e => {
                //setLoader(false);
                ToastsStore.error("Something went wrong. Difficult to show credentials");
            })

        //let credentials = { "email": this.state.emailNonDnd, "username": this.state.usernameNonDnd, "password": this.state.passwordNonDnd, "type": "NUMERIC NON DND", "panel": "http://message.reachlocalads.com/" }
        //credentials.push(credentials);
        // this.pushalldata();

    }



    editDetails(email, password, type, panel) {

        this.pushalldata();
        //this.populateForEditDetails();
        //let temp1 = this.state.storedCredentialsinfo;
        //temp1.email = this.state.storedCredentialsinfo[email.email];
        //temp1.password = this.state.storedCredentialsinfo[email.password];


        this.setState({
            viewCredentialsDetails: true,
            addCredentialsDetails: false,
            credentialsEmail: email.email,
            credentialsUsername: email.username,
            credentialsPassword: email.password,
            credentialsType: email.type,
            credentialsPanel: email.panel,
            credentialsIndex: email.index,
            storeCredentialindex: email,
            storedCredentialsinfo:email,
            //storedCredentialsinfo: this.state.allCredentials,


        })
        console.log("Bye", this.state.credentialsEmail);
        console.log("HI", email);
        console.log("HI0", email.username);
        console.log("HI1", email.password);
        console.log("HI2", email.type);
        console.log("HI3", email.panel);
    }

    addDetails() {
        this.pushalldata();
        this.setState({
            addCredentialsDetails: true,
            viewCredentialsDetails: false,
            storedCredentialsinfo: this.state.allCredentials,


        })
        console.log("Inside add details....");
    }

    closePopup() {
        this.setState({
            isCredentialsEdit: false,
            isCredentialsAdd: false,
            viewCredentialsDetails: false,
            addCredentialsDetails: false,
            //formControls: null,
            formControls: this.state.storedCredentialsinfo,

        })

    }

    saveCredentialsDetails() {
        console.log("Inside save credentials....");


        const body = {
            email: this.state.storedCredentialsinfo.email,
            username: this.state.storedCredentialsinfo.username,
            password: this.state.storedCredentialsinfo.password,
            mobile: this.state.storedCredentialsinfo.mobile,
            type: this.state.storedCredentialsinfo.type.value,
            businessUid: this.props.businessUid,
        }

        let apiKey = "Abbchdhskhdsd61671617616";

        addIdPasswordDetails(this.state.storedCredentialsinfo.email, this.state.storedCredentialsinfo.username,
            this.state.storedCredentialsinfo.password, this.state.storedCredentialsinfo.mobile,
            this.state.storedCredentialsinfo.type.value, this.props.businessUid, apiKey)
            .then(response => response.json())
            .then(data => {
                //let data = {"success":true,"message":null,"allowedActions":[],"uid":"70005000175","name":"Anand ","address":"112,Xyz","landmark":"India","locality":"Kalkaji","city":"Delhi","state":"Delhi","pincode":"110084","logo":"","website":"anand.com"};
                if (data.success) {

                    console.log("Inside add data to backend", this.state.storedCredentialsinfo.email,
                        this.state.storedCredentialsinfo.username,
                        this.state.storedCredentialsinfo.password,
                        this.state.storedCredentialsinfo.mobile,
                        this.state.storedCredentialsinfo.type.value, this.props.businessUid);
                    ToastsStore.success("Details Saved Successfully !");


                } else {
                    ToastsStore.error(data.message);
                }
            }).catch(error => {
                console.log(error);
                ToastsStore.error("Something went wrong, Please Try Again Later ");
            })

        console.log("add new user", body);


    }


    editCredentialsDetails() {
        console.log("Inside edit credentials....");
        const body = {
            email: this.state.storedCredentialsinfo.email,
            username: this.state.storedCredentialsinfo.username,
            password: this.state.storedCredentialsinfo.password,
            mobile: this.state.storedCredentialsinfo.mobile,
            type: this.state.storedCredentialsinfo.type.value,
            businessUid: this.props.businessUid,
        }


        editIdPasswordDetails(this.state.storedCredentialsinfo.email, this.state.storedCredentialsinfo.username,
            this.state.storedCredentialsinfo.password, this.state.storedCredentialsinfo.mobile,
            this.state.storedCredentialsinfo.type.value, this.props.businessUid)
            .then(response => response.json())
            .then(data => {
                //let data = {"success":true,"message":null,"allowedActions":[],"uid":"70005000175","name":"Anand ","address":"112,Xyz","landmark":"India","locality":"Kalkaji","city":"Delhi","state":"Delhi","pincode":"110084","logo":"","website":"anand.com"};
                if (data.success) {

                    console.log("Inside edit existing data of backend", this.state.storedCredentialsinfo.email,
                        this.state.storedCredentialsinfo.username,
                        this.state.storedCredentialsinfo.password,
                        this.state.storedCredentialsinfo.mobile,
                        this.state.storedCredentialsinfo.type.value, this.props.businessUid);
                    ToastsStore.success("Details Saved Successfully !");


                } else {
                    ToastsStore.error(data.message);
                }
            }).catch(error => {
                console.log(error);
                ToastsStore.error("Something went wrong, Please Try Again Later ");
            })


        console.log("edit existing user", body);

    }

    handleChange(event) {
        let name = 'type';
        let val = event;
        let temp = this.state.storedCredentialsinfo;
        temp[name] = val;
        this.setState({
            storedCredentialsinfo: temp
        })
        console.log("change", temp);
        console.log("State-hndlchange", this.state.storedCredentialsinfo);
    }

    bChangeHandler(event) {
        let name = event.target.name;
        let val = event.target.value;
        let temp = this.state.storedCredentialsinfo;
        temp[name] = val;
        this.setState({
            storedCredentialsinfo: temp

        })
        console.log("bhandler", temp);
        console.log("State-hndlr", this.state.storedCredentialsinfo);
    }

    /*populateForEditDetails() {

        this.setState({
            formControls: storeCredentialindex,


        })

    }*/



    render() {

        let credentials = [
            { "email": this.state.email, "username": this.state.username, "password": this.state.password, "mobile":this.state.mobile, "type": "WhatsApp", "typeValue":"wapp", "panel": "http://wa.reachlocalads.com/" },
            { "email": this.state.emailSms, "username": this.state.usernameSms, "password": this.state.passwordSms, "mobile":this.state.mobileSms, "type": "SIM SMS", "typeValue":"sim-sms", "panel": "http://message.reachlocalads.com/" },
            { "email": this.state.emailVoice, "username": this.state.usernameVoice, "password": this.state.passwordVoice, "mobile":this.state.mobileVoice, "type": "Voice OBD", "typeValue":"voice", "panel": "http://voice-dnd.reachlocalads.com/" },
            { "email": this.state.emailDnd, "username": this.state.usernameDnd, "password": this.state.passwordDnd, "mobile":this.state.mobileDnd, "type": "DLT-DND", "typeValue":"dlt-dnd", "panel": "http://message.reachlocalads.com/" },
            { "email": this.state.emailNonDnd, "username": this.state.usernameNonDnd, "password": this.state.passwordNonDnd, "mobile":this.state.mobileNonDnd, "type": "NUMERIC NON DND", "typeValue":"numeric-non-dnd",  "panel": "http://message.reachlocalads.com/" },

        ]

        //var credpass = credentials.filter(function (e) { return e != null });
        //console.log("filtered", credpass);

        return (

            <React.Fragment>

                <div className="" style={{ marginLeft: "85%", marginBottom: "1%" }}>
                    <button onClick={() => this.addDetails()} className="btn btn-fill btn-success margin-left--auto">Add New Panel User</button>
                </div>

                {utils.isAdmin && this.state.storedCredentialsinfo == null ? console.log("no data...") : console.log("data present..." + this.state.storedCredentialsinfo)}



                {utils.isSuAdmin &&
                    <React.Fragment>
                        <div className="leads-table-wrapper card-custom" style={utils.isMobile ? { maxHeight: "60vh" } : { maxHeight: "70vh" }}>


                            <table className="client">
                                <thead className="">
                                    <tr className="">
                                        <th>Email</th>
                                        <th>Username</th>
                                        <th>Password</th>
                                        <th>Service Name</th>
                                        <th>Panel URL</th>

                                        <th className="" style={{ width: '15%' }}>Action</th>

                                    </tr>
                                </thead>

                                <tbody className="">
                                    {credentials && credentials.map((item, index) => {

                                        return (

                                            <tr key={index} className="">
                                                {item.password ? <td className="">{item.email ? item.email : ""}</td> : console.log("empty")}
                                                {item.password ? <td className="">{item.username ? item.username : ""}</td> : console.log("empty")}
                                                {item.password ? <td className="">{item.password ? item.password : ""}</td> : console.log("empty")}
                                                {item.password ? <td className="">{item.type ? item.type : ""}</td> : console.log("empty")}
                                                {item.password ? <td className="">{item.panel ? item.panel : ""}</td> : console.log("empty")}


                                                {item.email ? <td className="">
                                                    <button onClick={() => this.editDetails({ email: item.email, username: item.username, password: item.password, mobile: item.mobile, type: item.type, typeValue: item.typeValue, panel: item.panel, index: index })} className="ui icon right labeled tiny button">
                                                        <i aria-hidden="true" className="right arrow icon"></i>
                                                        Edit
                                                    </button>
                                                </td> : console.log("empty")}
                                            </tr>

                                        );
                                    })}
                                </tbody>

                            </table>

                        </div>
                    </React.Fragment>
                }
                {

                    this.state.viewCredentialsDetails &&
                    <Popup title={"ID Password Details"} togglePopup={this.closePopup.bind(this)} >
                        <IdPasswordDetails
                            closePopup={this.closePopup.bind(this)}
                            isCredentialsEdit={true}
                            infoEmail={this.state.credentialsEmail}
                            infoPassword={this.state.credentialsPassword}
                            infoType={this.state.credentialsType}
                            infoPanel={this.state.credentialsPanel}
                            //emailInfo={this.state.storeCredentialindex.email}
                            //passwordInfo={this.state.storeCredentialindex.password}
                            //typeInfo={this.state.storeCredentialindex.type}
                            //panelInfo={this.state.storeCredentialindex.panel}
                            info={this.state.storeCredentialindex}
                            editCredentialsDetails={this.editCredentialsDetails.bind(this)}
                            saveCredentialsDetails={this.saveCredentialsDetails.bind(this)}
                            bChangeHandler={this.bChangeHandler.bind(this)}
                            handleChange={this.handleChange.bind(this)}
                        ></IdPasswordDetails>

                    </Popup>
                }

                {

                    this.state.addCredentialsDetails &&
                    <Popup title={"ID Password Details"} togglePopup={this.closePopup.bind(this)} >
                        <IdPasswordDetails
                            closePopup={this.closePopup.bind(this)}
                            isCredentialsAdd={true}
                            editCredentialsDetails={this.editCredentialsDetails.bind(this)}
                            saveCredentialsDetails={this.saveCredentialsDetails.bind(this)}
                            bChangeHandler={this.bChangeHandler.bind(this)}
                            handleChange={this.handleChange.bind(this)}


                        ></IdPasswordDetails>

                    </Popup>
                }


            </React.Fragment>

        );
    }


}


