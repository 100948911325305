import React from 'react';
// import {Link} from 'react-router-dom';
import utils from '../../Services/utility-service';



export default function CloudTelephone(props){

    const cards = [{num:1,text:"Click To Call"},{num:2,text:"Missed Call"}];
    const displayCard = cards && cards.map((item,index)=>{
        return(
            <div key={index} className="ui card">
                {/* <Link to={{pathname:item.url}}> */}
                    <div className="content">
                        <div className="header pad--half text--center" style={{fontSize:"16px",color:"#4183c4"}} onClick={()=>props.click(item.num)}>{item.text}</div> 
                    </div>
                {/* </Link> */}
            </div>
        )
    })
    return(
        <section className="wrapper-container">
            <article className="card-custom  pad">
                <h4 className="ui header">CLOUD TELEPHONY</h4>
            </article>
            <article className={`${utils.isMobile ? "ui two cards":"ui four cards"}`}>
                { displayCard }
            </article>
        </section>
    )
}