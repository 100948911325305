import React, { useState} from "react";
import SvgIcon from '../Svg-icon/Svg-icon';
import "./Accordion.css";

function Accordion(props) {

  // const content = useRef(null);
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");

  function toggleAccordion() {   
    setActiveState(setActive === "" ? "active" : ""); 
    const con = document.getElementsByClassName("accordion__content"); 
    for(let i=0; i < con.length; i++){
      con[i].style.maxHeight = "0px";
    }
    setHeightState(
      setActive === "active" ? "0px" : "700px"
    );
    setRotateState(
      setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
    );
    props.click(props.code);
  }

  return (
    <div className="accordion__section" style={{borderBottom: "1px solid lightgray"}}>
      <button className={`accordion ${setActive}`} style={{backgroundColor:`${props.backgroundColor ? props.backgroundColor: '#eeeeee'}`}} onClick={toggleAccordion}>
        { 
          props.path ? <React.Fragment><img src={props.path} alt="icon" width={props.width} style={{marginRight:"1.5%"}} /> </React.Fragment>: null
        }    
        <div className="accordion__title" dangerouslySetInnerHTML={{ __html: props.title }}/>  
        <SvgIcon icon="chevron-down" className={setRotate} classes="svg--big" />
      </button>
      <div
        id="content"
        style={{ maxHeight: `${setHeight}` }}
        className='accordion__content'
      >
        {props.children}
      </div>
    </div>
  );
}

export default Accordion;
