import React,{useEffect,useState} from 'react';
import ContactUS from '../ContactUS/ContactUS';
import {whatsAppMobile} from '../../Services/whatsApp-service';


export default function ConnectWithUs(props){

    const [url, setURL] = useState(()=>"https://api.whatsapp.com/send?phone=");
    const [mob, setMob] = useState(null);
    const [msg, setMsg] = useState(null);

    useEffect(()=>{
        whatsAppNumber();
    },[])

    const whatsAppNumber = () =>{
        // let url= `${utils.isMobile ? "https://api.whatsapp.com/send?" :"https://web.whatsapp.com/send?"}`;
        let url = "https://api.whatsapp.com/send?phone=";
        const body={};
        whatsAppMobile(body)
        .then (r => r.json())
        .then(data =>{
            if(data.success){             
                let mob = data.mobile.substring(1);
                let urlPh=url.concat(mob);
                // let urlPh=url.concat(`phone=${data.mobile}`);
                let finalURL = urlPh.concat("&text=Tell+me+more+about+ReachLocalAds+Hyperlocal+Marketing+Platform");
                
                setURL(finalURL);
                setMob(data.mobile);
                // this.setState({
                //     url:finalURL,
                //     mob:data.mobile
                // })   
            }
            else{
                setMsg(data.message);            
            }
        })
        .catch( e=>{
            console.error(e);
            // ToastsStore.error("Something Went Wrong. Please try again later.!!!");
        })        
    }

    return(
        <section>
            {/* <h2>Please connect with us to get this implemented</h2> */}
            <ContactUS url={url} mob={mob} title="Please connect with us  to get this implemented"/>
            {
                msg &&
                <p>{msg}</p>
            }
        </section>
    )
}