import React from 'react';
import utils from '../../../Services/utility-service';

export default function Bottom(props){
    return(
        <article>
            <div style={utils.isMobile ? null :{display:"flex", margin:"3% 10%"}}>
                <span style={{textAlign:"start",padding:`${utils.isMobile ? "0 20%" : "0 10%"}`}}>
                    <h1 style={{fontFamily:"monospace"}}>{props.title}</h1>
                    <ol style={{fontSize:"15px", fontWeight:"600", fontFamily:"monospace"}}>
                        <li style={{marginBottom:"5px"}}>Secure your family’s financial future through life insurance cover</li>
                        <li style={{marginBottom:"5px"}}>A life insurance product to address multiple needs of: 
                            <div>• Family Protection </div>
                            <div>• Wealth Accumulation</div>
                        </li>
                        <li style={{marginBottom:"5px"}}>Get rewarded for loyalty through increasing Loyalty Sum Assured</li>
                        <li style={{marginBottom:"5px"}}>Grow your wealth through regular and increasing additions and bonus</li>
                    </ol>
                </span>
                <img src={props.src} alt="features" width={props.width} height={props.height} style={{marginTop:`${props.top}`}}/>
            </div>
            <a className="btn btn-fill btn-orange" href="#data-form">Get Full Information</a>
        </article>
    )
}