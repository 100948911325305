import React from 'react';
import utils from '../../../Services/utility-service';

export default function Banner (props){
    // var r=parseInt(props.row);
    var c= parseInt(props.col);
    
    return(
        <article style={{backgroundColor:"rgba(247,249,255,1)",paddingBottom:"15px"}}>
            <div className="text--center" style={utils.isMobile ? {fontWeight:"600",fontSize:"large"} : {fontWeight:"600",fontSize:"xx-large"}}>Key Features</div>
            <div className="banner justify-content--center" style={{flexDirection:"column",paddingTop:"5%"}}>
                <div style={utils.isMobile ? {display:"flex",marginBottom:"3%"}:{display:"flex",margin:"0 20% 5% 20%"}} className="align-space-around">
                    {
                        props.bannerImg.map((item,index)=>{
                            if(index <= parseInt(props.col-1)){
                                return(
                                    <span key={index} style={utils.isMobile ? {border:"1px solid lightgray",display:"block",width:"100px",height:"120px",backgroundColor:"white"}: {border:"1px solid lightgray",display:"block",width:"200px",height:"200px",backgroundColor:"white"}}>
                                        <img src={item.path} width="50%" alt="features" style={{marginTop:"20%"}}/>
                                        <div className="margin-left--auto margin-right--auto" style={utils.isMobile ? {fontSize:"1em", fontWeight:"600"}:{fontSize:"15px", fontWeight:"600"}}>{item.caption}</div>
                                    </span>
                                )
                            }
                        })
                    }
                </div>
                <div style={utils.isMobile ? {display:"flex",margin:"0 1%% 5% 1%%"}:{display:"flex",margin:"0 20% 5% 20%"}} className="align-space-around">
                    {
                        props.bannerImg.map((item,index)=>{
                            if(index >= c){
                                return(
                                    <span key={index} style={utils.isMobile ? {border:"1px solid lightgray",display:"block",width:"100px",height:"120px",backgroundColor:"white"}: {border:"1px solid lightgray",display:"block",width:"200px",height:"200px",backgroundColor:"white"}}>
                                        <img src={item.path} width="50%" alt="features" style={{marginTop:"20%"}}/>
                                        <div className="margin-left--auto margin-right--auto"  style={utils.isMobile ? {fontSize:"1em", fontWeight:"600"}:{fontSize:"15px", fontWeight:"600"}}>{item.caption}</div>
                                    </span>
                                )
                            }
                        })
                    }
                </div>
            </div>
        </article>
    )
}