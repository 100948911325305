const serviceTypes = [{ "value": "wapp", "label": "WhatsApp" }, 
{"value": "dlt-dnd", "label": "DLT-DND" },
{"value": "numeric-non-dnd", "label": "NUMERIC NON DND" },
{"value": "sim-sms", "label": "SIM SMS" },
{"value": "voice", "label": "Voice OBD" },
{"value": "dlt-normal", "label": "DLT NORMAL" },
]

const serviceTypesAdd = [ 
{"value": "dlt-dnd", "label": "DLT-DND" },
{"value": "numeric-non-dnd", "label": "NUMERIC NON DND" },
{"value": "sim-sms", "label": "SIM SMS" },
{"value": "voice", "label": "Voice OBD" },
{"value": "dlt-normal", "label": "DLT NORMAL" },
]
export default serviceTypes;