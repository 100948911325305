import React from 'react';
import './Card.css';
import Modal from '../../Components/Modal/Modal';
import CardHeading from './CardHeading';
import OneCard from '../../Components/DashboardObjective/OneCard';
import utils from '../../Services/utility-service';



export default function Card(props){


    function handleClick(code,showContact){     
        if(showContact){
            props.operatePopup();
        }
        else{
            props.click(code,"oneCard");
        } 
    }

    return(
        <React.Fragment>
            {
                props.layoutType && props.layoutType !== "CARDHEADING" &&
                <article className="pad"> 
                    <p style={utils.isMobile ? {fontSize:"1.0rem"}:{fontSize:"1.3rem"}}>{props.card && props.card.boxContent && props.card.boxContent.text}</p>
                    { props.layoutType && props.layoutType === "CARD" &&
                        <article className="flex flex-wrap pad--d">
                        { 
                            props.card && props.card.elements && props.card.elements.map((item,index)=>{
                                return(
                                    <div className="card-border pad mar" style={{cursor:"pointer"}} key={index} onClick={()=>handleClick(item.code,item.showContactUs)}>
                                        <img src={item.icon} alt="icon" width="15%"/>
                                        <p style={utils.isMobile ? {fontSize:"1.1rem"}:{fontSize:"1.4rem"}}><b>{item.title}</b></p>
                                        <p style={utils.isMobile ? {fontSize:"1.0rem"} : {fontSize:"1.2rem"}}>{item.text}</p>
                                    </div>
                                )
                            })
                        }                       
                        </article>
                    }
                    { props.layoutType && props.layoutType === "ONECARD" &&
                        <Modal active="true"  close={props.closeModal} back={props.closeModal}>
                        
                            <OneCard oneCard={props.oneCard} 
                            currCard={props.oneCard.parentContent}
                            click={props.click}
                            />
                      
                        </Modal>
                    }
                </article>
        }
        {
            props.layoutType && props.layoutType === "CARDHEADING" &&
            <Modal active="true"  close={props.closeModal} back={props.back}>
                <CardHeading cardHeading={props.cardHeading} click={props.click}/>
            </Modal>
        }

        </React.Fragment>
    )
}