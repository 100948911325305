
import React from 'react';
import CircularLoader from '../../circular-loader/circular-loader';
import { fetchLeadCampaign } from '../../../Services/lead-service';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import { Link } from 'react-router-dom';
import utils from '../../../Services/utility-service';

export default class TableLeadCampaign extends React.Component {
    constructor() {
        super();
        this.state = {
            campaigns: [],
            loader: false,
            hasNext: false
        }
    }

    componentDidMount() {
        this.showCampaign();
    }
    showCampaign(start, end) {
        const body = {
            // ammId: this.state.ammId,
            // bamId: this.state.bamId,
            // mediumId: this.state.mediumId,
           // start: this.state.start,
            //maxResults: 30,
            //businessUid:"70002160805",

        }
        // if(start && end){
        //     body['createdStart'] = start;
        //     body['createdEnd'] = end;
        // }
        this.setState({
            loader: true
        })
        fetchLeadCampaign(body)
            .then((response) => response.json())
            .then(data => {
                if (data.success) {
                    if (data.campaigns && data.campaigns.length > 0) {
                        this.setState({
                            campaigns: this.trimLongDescription(data.campaigns),
                            hasNext: (data.campaigns.length === 30),
                            // showClientDetails: data.showClientDetails
                        })
                    } else {
                        this.setState({
                            hasNext: false
                        })
                    }
                } else {
                    ToastsStore.error(data.message);
                }
                this.setState({
                    loader: false
                })
            })
            .catch(error => {
                console.error(error);
                ToastsStore.error("Something went wrong, Please Try Again Later ");
                this.setState({
                    loader: false
                })
            })
    }
    trimLongDescription(data) {
        if (data && data.length > 0) {
            data.forEach(ele => {
                if (ele.desc && ele.desc.length > 100) {
                    ele.desc = ele.desc.slice(0, 100) + '...';
                }
            });
        }
        return data;
    }
    render() {
        return (
            <main>
                <Link to="/leads/management"><button className="ui icon left labeled tiny button"><i aria-hidden="true" className="left chevron icon"></i>Back</button></Link>
                <article className="card-custom pad--half flex flex-space--btw">
                    <h4 className="ui header">Lead Campaign</h4>
                    <section className="margin-left--auto margin-right--quar">
                        {/* {
                            utils.hasRole('campaign_create') && */}
                        <div className="senderId-action--wrapper">
                            <Link to="/leads/campaign-create"><button className="btn btn-fill btn-success margin-left--auto">Create New Lead Campaign</button></Link>
                        </div>
                        {/* } */}
                    </section>
                </article>
                <article className="card-custom margin-top leads-table-wrapper" style={utils.isMobile ? { maxHeight: "60vh" } : { maxHeight: "70vh" }}>
                    <table className="client">
                        <thead>
                            <tr>
                                <th>Campaign ID</th>
                                <th>Campaign Name</th>
                                <th>Campaign URL</th>
                                <th>Campaign Status Group</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.campaigns && this.state.campaigns.map((item, index) => {

                                return (

                                    <tr key={index} className="">
                                        {item.id ? <td className="">{item.id ? item.id : ""}</td> : console.log("empty")}
                                        {item.id ? <td className="">{item.name ? item.name : ""}</td> : console.log("empty")}
                                        {item.id ? <td className="">{item.url ? item.url : ""}</td> : console.log("empty")}
                                        {item.id ? <td className="">{item.statusGroupId ? item.statusGroupId : ""}</td> : console.log("empty")}

                                    </tr>
                                );


                            })
                            }
                        </tbody>
                    </table>
                </article>
                {
                    this.state.loader &&
                    <div className="margin-top--double">
                        <CircularLoader stroke={"#0c73a5"} size={"36"} buttonSize={"50px"}></CircularLoader>
                    </div>
                }
                <ToastsContainer position={ToastsContainerPosition.TOP_RIGHT} lightBackground store={ToastsStore} />
            </main>
        )
    }
}